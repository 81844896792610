.diagramContainer {
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 200px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diagramContainer iframe {
    width: 100%;
    height: 100%;
    border: none;
    flex-grow: 1;
    max-width: 100%;
    max-height: 100%;
}
