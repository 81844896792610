/* GenerateProposalStructureSBIR.css */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #2c3e50;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  color: #ecf0f1;
  font-family: 'Arial', sans-serif;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  backdrop-filter: blur(5px);
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.btn {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-submit {
  background-color: #27ae60;
  color: white;
}

.btn-submit:hover {
  background-color: #2ecc71;
}

.btn-cancel {
  background-color: #c0392b;
  color: white;
}

.btn-cancel:hover {
  background-color: #e74c3c;
}

textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #34495e;
  background-color: #34495e;
  color: #ecf0f1;
  resize: none;
  font-family: 'Arial', sans-serif;
}