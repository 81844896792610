.BlogTitle {
    font-size: 50px;
    color: #00b0dc;
}

.blog {
    min-height: 82vh;
    padding-top: 110px;
    margin-bottom: 40px;
}

.articlePreviewsDiv {
    display: flex;
    flex-direction: row;
    gap: 40px;  /* This controls the gap between each article */
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 25%;  /* Adding padding on the sides of the container for spacing */
}

@media (max-width: 768px) {
    .articlePreviewsDiv {
        padding: 10px;
        margin-bottom: 20px;
        padding-bottom: 50px;
    }
    .BlogTitle {
        font-size: 30px;
    }
}