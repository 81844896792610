.dashboard {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
.dashboard-main-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  align-items: center;
  position: relative;
}
.compliance-matrix-btn-div {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
}
.steps .step {
  margin-bottom: 20px;
}
.fade-in {
  animation: fadeInEffect 2s;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.step h3 {
  color: #007bff;
}

.step p {
  margin-top: 5px;
  line-height: 1.6;
}
.steps-btn-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px 0;
}
.go-back-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.go-back-div button {
    width: fit-content;
}
.AISidebar {
  /* Style your sidebar as needed */
  width: 300px; /* Example width */
  /* More styles... */
}

/* Enhancements for the dropdown container */
.dropdown-container {
    position: relative;
    width: 100%; /* Full width to match parent */
    border: 1px solid #cccccc;
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* More padding for inner spacing */
    background-color: #f9f9f9; /* Slightly off-white background for contrast */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 20px; /* Spacing below the dropdown */
}

/* Enhancements for checkbox labels and interaction */
.dropdown-container label {
    display: flex;
    align-items: center; /* Ensure vertical alignment */
    margin-bottom: 10px; /* More space between items */
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

/* Hover effect for labels */
.dropdown-container label:hover {
    background-color: #e6e6e6; /* Light grey background on hover */
    border-radius: 6px; /* Rounded corners for the hover effect */
}

/* Styling for product names or group labels */
.dropdown-container strong {
    display: block;
    margin-top: 25px; /* Increase space before new product name */
    color: #000; /* Darker color for better readability */
    font-size: 16px; /* Slightly larger font size */
    padding-bottom: 5px; /* Padding at the bottom */
}

/* Adjustments for checkboxes for better alignment and interaction */
.dropdown-container input[type="checkbox"] {
    margin-right: 15px; /* Increase space between checkbox and label */
    cursor: pointer; /* Ensure cursor changes to pointer over checkboxes */
    width: 20px; /* Wider for easier clicking */
    height: 20px; /* Taller for easier clicking */
}

/* Special styling for the 'All' selector for clarity */
.dropdown-container > div:first-child label {
    font-weight: bold; /* Make 'All' stand out */
    margin-top: 0; /* Remove top margin for alignment */
}

.dropdown-check-list > label, .product-name-label, .file-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space between checkboxes */
}

/* Specifically style product names differently if needed */
.product-name-label {
    font-weight: bold; /* Make product names stand out */
    /* Additional styling for product names if necessary */
}

.file-label {
    margin-left: 20px; /* Indent files under their product */
    font-weight: 400 !important;
    font-size: 12px;
}

input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and text */
    cursor: pointer;
}

/* Style for button icon */
.icon {
  /* Add styling for your icon */
}

/* Style for button text */
.button-text {
  margin-left: 10px; /* Space between icon and text */
}



/* Adjust main content margin based on sidebar state */
.dashboard-main-content {
  margin-left: 60px; /* Same as sidebar width */
  margin-right: 60px; /* Same as sidebar width */
  margin: 0 60px;
  transition: margin 0.8s;
  /* Add other styling as needed */
}

/* Adjust margin-left for main content when sidebar is collapsed */
.dashboard-main-content.sidebar-collapsed {

}

.dashboard-main-content.AIsidebar-open {
  transition: margin 0.7s;
}

.manageDBTab {
    display: flex;
    flex-direction: column;
}
.manageDBTab button {
    margin-bottom: 25px;
}
.newProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.newProduct ul li {
    display: flex;
    text-align: left;
    align-items: center;
}
.newProduct ul li button {

}
.upload-success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.generateProposalTab {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dashboard-console {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: space-around;
    padding: 20px;
    align-items: center;
    width: 50%;
    margin: 2rem auto;
}
.allProducts {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    justify-content: space-around;
    padding: 20px;
    align-items: center;

}
.product-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;



}
.product1 {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: space-around;
    padding: 20px;
    width: 40%;
    align-items: center;
    height: fit-content;
    margin: 10px;
}
.product {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: space-around;
    padding: 10px;
    width: 30%;
    align-items: center;
    margin: 10px;
}
.product1 ul,
.product ul {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0;
}
.product1 ul li,
.product ul li {
    display: flex;
    text-align: left;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.product1 ul li p,
.product ul li p {
    font-size: auto;
    width: 85%;
}
.dashboard h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.dashboard input[type="file"] {
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
}
.inputProductName {
  padding: 10px 15px;
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
  font-size: 16px; /* Comfortable font size */
  color: #333; /* Readable text color */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Space between this input and the next element */
}

.inputProductName:focus {
  border-color: #007bff; /* Highlight color on focus */
  box-shadow: 0 0 0 2px rgba(0,123,255,.25); /* Subtle glow effect */
  outline: none; /* Remove default focus outline */
}

.dropzone:hover {
  border-color: #0056b3; /* Darker blue on hover */
  background-color: #e2e6ea; /* Slightly darker background on hover */
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.dashboard button {
  display: block;
  padding: 10px;
  margin: 10px;
  background-color: #00b0dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}
.streamed-content-html {
    display: flex;
}
.file-upload-container {
  margin: 20px 0;
}

.file-upload-label {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff; /* Blue */
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.file-upload-label:hover {
  background-color: #0056b3; /* Darker blue */
}

.file-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.file-info p {
  margin: 0;
  font-size: 14px;
  flex-grow: 1; /* Ensure the file name takes up the space */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  /* Add dimensions or other styles as needed */
  width: 50%; /* Example width */
  height: 200px; /* Example height */
  justify-content: center;
  color: black; /* Example text color */
  font-size: 30px;
  display: flex;
  align-items: center;
}

.remove-file-button {
  margin-left: 10px !important;
  cursor: pointer !important;
  background-color: #dc3545 !important; /* Bootstrap danger color for delete actions */
  border: none !important;
  color: white !important;
  border-radius: 50% !important; /* Makes the button circular */
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 1 !important; /* Adjust line height to vertically center the 'X' */
}

.remove-file-button:hover {
  background-color: #c82333; /* Darker shade for hover effect */
}

.streamed-content-container {
    max-height: 90%;
    overflow: auto;
}
.streamed-content-container-proposal-structure {
    max-height: 90%;
    overflow: auto;
    width: 100%;
}
.streamed-content h1,
.streamed-content {
    text-align: left;
}
.dashboard button:hover {
  background-color: #0056b3;
}

.dashboard button:disabled {
  background-color: #cccccc;
  cursor: default;
}
.proposal-process-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dashboard a {
  display: inline-block;
  color: blue;
  text-decoration: underline;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.streamed-content-container {
  font-family: Arial, sans-serif;
  margin: 20px;
}

.compliance-matrix table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.compliance-matrix th, .compliance-matrix td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.compliance-matrix th {
  background-color: #4CAF50;
  color: white;
}

.compliance-matrix tr:nth-child(even) {background-color: #f2f2f2;}

.compliance-matrix tr:hover {background-color: #ddd;}

.compliance-matrix td, .compliance-matrix th {
  padding: 12px 20px;
}

.compliance-matrix th {
  padding-top: 12px;
  padding-bottom: 12px;
}

.streamed-content-proposal-structure {
    overflow: auto;
}
.streamed-content-proposal-structure h1,
.streamed-content-proposal-structure {
    text-align: left;
}
.streamed-content-container-proposal-structure h2 {
  color: #333;
}

.streamed-content-proposal-structure {
  display: block; /* Initial display set to block, will be overridden by inline styles */
  margin-bottom: 20px;
}
.excel-export-button {
    width: fit-content;
}
.proposal-volumes-div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
}
.proposal-volume {
  display: none; /* Enable flex layout */
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(33.333% - 20px); /* Subtract the gap from the total width */
  min-height: 100px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}


.proposal-volume h3 {
  color: #0275d8;
  margin-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.proposal-volume ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 0;
}
.submitProposalName {
    align-self: center;
}
/* Styling for buttons with specific patterns in their class name */
button[class*="remove-section-btn-"],
button[class*="add-section-btn-"] {
  cursor: pointer;
  margin: 5px;
  padding: 8px 12px;
  border: none;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: bold;
  width: 25px;
  height: 25px;

}

/* Specific styles for remove-section buttons */
button[class*="remove-section-btn-"] {
  background-color: #dc3545; /* Red for deletion actions */
  display: flex;
  align-items: center;
  justify-content: center;
}

button[class*="remove-section-btn-"]:hover {
  background-color: #c82333;
}

/* Specific styles for add-section buttons */
button[class*="add-section-btn-"] {
  background-color: #28a745; /* Green for addition actions */
  display: flex;
  align-items: center;
  justify-content: center;
}

button[class*="add-section-btn-"]:hover {
  background-color: #218838;
}
.test2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
    margin-top: 0;
}
.btn-wrapper-div-structure {
    display: flex;
}
.file-name-products {
  display: inline-block; /* Allows the use of max-width */
  max-width: 90%; /* Leaves space for the download icon */
  margin-right: 10px; /* Space before the download icon */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; /* Indicates the item is clickable */
  color: #007bff; /* Bootstrap primary blue for links, adjust as needed */
  text-decoration: underline; /* Indicates it's clickable */
}

.file-name-products:hover {
  color: #0056b3; /* Darker blue on hover, adjust as needed */
  text-decoration: none; /* Optional style on hover */
}
.proposal-volume-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  text-align: left;
  width: 100%;
}

.proposal-volume-remove-btn {
  background-color: #f44336 !important; /* Red color for deletion, adjust as needed */
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  padding: 5px 10px !important;
  border-radius: 50% !important; /* Circular button */
  font-size: 16px;
  height: 30px;
  width: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.proposal-volume-remove-btn:hover {
  background-color: #d32f2f; /* Darker shade for hover effect */
}


/* General styling for all toggle editability buttons */
[class^="toggle-editability-btn-"] {
  padding: 8px 16px !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

[class^="toggle-editability-btn-"]:hover {
  background-color: #0056b3 !important;
}

[class^="toggle-editability-btn-"]:active {
  background-color: #004289 !important;
}

[class^="toggle-editability-btn-"]:disabled {
  background-color: #cccccc !important;
  cursor: not-allowed !important;
}

.test2 ul,
.test2 ol {
    padding: 0;
    font-size: 13px;
}
.editable-content-structure {
    width: 90%;
}
.section-and-remove-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

/* Dashboard.css */

/* Container for SAM opportunities */
.sam-opportunities {
    background-color: #f9f9f9; /* Light background */
    padding: 20px; /* Spacing around the content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 20px;
}

.sam-opportunities h2 {
    color: #333; /* Dark text color */
    margin-bottom: 20px; /* Spacing below the heading */
}

.sam-opportunities ul {
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: space-between; /* Space out the items evenly */
    list-style-type: none; /* Remove default list style */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove default margin */
}

.sam-opportunities li {
    background-color: #fff; /* White background for list items */
    padding: 15px; /* Spacing inside list items */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Slightly rounded corners */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow */
    flex: 1; /* Allow items to grow and shrink equally */
    margin: 0 10px; /* Space between items */
    display: flex; /* Flexbox for internal layout */
    flex-direction: column; /* Vertical layout */
    justify-content: space-between; /* Space out content inside the items */
    text-align: left;
}

.sam-opportunities li:first-child {
    margin-left: 0; /* Remove left margin for the first item */
}

.sam-opportunities li:last-child {
    margin-right: 0; /* Remove right margin for the last item */
}

.sam-opportunities h3 {
    margin: 0 0 10px 0; /* Space below the title */
    color: #0073e6; /* Blue color for title */
}

.sam-opportunities p {
    margin: 5px 0; /* Space between paragraphs */
    color: #555; /* Dark grey text color */
}

.sam-opportunities p span {
    display: block; /* Display contact details on new lines */
    margin-top: 5px; /* Spacing between contact details */
}

.sam-opportunities a {
    display: inline-block; /* Make the link an inline block */
    margin-top: 10px; /* Space above the link */
    padding: 10px 15px; /* Padding inside the link */
    background-color: #0073e6; /* Blue background for the link */
    color: #fff; /* White text color */
    text-decoration: none; /* Remove underline */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
    text-align: center;
    width: 50%;
    align-self: center;
    font-size: 12px;
}

.sam-opportunities a:hover {
    background-color: #005bb5; /* Darker blue on hover */
}
.sam-opportunities button {
    margin-top: 10px; /* Space above the button */
    padding: 10px 15px; /* Padding inside the button */
    color: #fff; /* White text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
    width: 50%;
    align-self: center;
    font-size: 12px;
}

.sam-opportunities button:hover {
}


/* Proposal Type Switch */
.proposal-type-switch {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.switch-label {
  margin-left: 10px;
  font-size: 16px;
}

.product-json {
  background-color: #f0f8ff; /* Light blue background */
  position: relative; /* Ensure the banner is positioned relative to this container */
}

.sbir-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  z-index: 10;
}


/* Custom CSS for better styling of the XML response */

.analysis-container {
    padding: 20px;
    background-color: #282c34;
    color: #abb2bf;
    border-radius: 8px;
    margin: 20px 0;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.fpds-opportunities {
    background-color: #1c1e22;
    padding: 20px;
    border-radius: 8px;
}

.fpds-opportunities h2 {
    color: #61dafb;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.fpds-opportunities p {
    color: #abb2bf;
}

.error {
    color: #e06c75;
}

.xml-item {
    background-color: #2c313a;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

.xml-item h3 {
    color: #e06c75;
}

.xml-item p {
    color: #abb2bf;
    margin: 5px 0;
}

.xml-item p strong {
    color: #61dafb;
}

.search-bar {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
}

.search-bar input {
    padding: 10px;
    font-size: 16px;
    border: 2px solid #61dafb;
    border-radius: 5px 0 0 5px;
    outline: none;
    width: 70%;
    color: #282c34;
    background-color: #abb2bf;
}

.search-bar input::placeholder {
    color: #2c313a;
}

.search-bar button {
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid #61dafb;
    border-radius: 0 5px 5px 0;
    background-color: #61dafb;
    color: #282c34;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.search-bar button:hover {
    background-color: #21a1f1;
}
