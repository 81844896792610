/* Container for the entire form */
.ai-prompt-container {
    margin: 20px auto 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 95%;
  }
  .ai-prompt-form {
    display: grid; /* Use grid layout for two columns */
    grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
    gap: 5px; /* Space between rows and columns */
  }
  /* Form title */
  .ai-prompt-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    margin-top: 0;
  }
  
  /* Instructional text */
  .ai-prompt-instructions {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  /* Section container */
  .ai-prompt-section {
    margin-bottom: 0px;
  }
  
  /* Label for each section */
  .ai-prompt-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    text-align: left;
    color: black;
  }
  
  /* Textarea for entering prompts */
  .ai-prompt-textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    resize: vertical;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Submit button */
  .ai-prompt-submit {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: auto;
    align-self: center;
  }
  
  .ai-prompt-submit:hover {
    background-color: #0056b3;
  }
  .ai-prompt-title-optional {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 5px;
    color: #333;
    margin-top: 0;
    font-style: italic;
  }

  .ai-prompt-refresh-button {
    background-color: transparent !important; /* Transparent background */
    border: none !important; /* Remove border */
    cursor: pointer !important; /* Pointer cursor for interactivity */
    padding: 0 !important; /* Remove default button padding */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: relative !important; /* Enable positioning for the tooltip */
    transition: transform 0.2s ease !important; /* Smooth scaling on hover */
  }
  
  .ai-prompt-refresh-button:hover {
    transform: scale(1.1) !important; /* Slight zoom effect on hover */
  }
  
  .ai-prompt-refresh-button:active {
    transform: scale(0.9) !important; /* Slight press effect */
  }
  
  .refresh-icon {
    width: 30px !important; /* Set icon size */
    height: 30px !important; /* Set icon size */
    transition: transform 0.2s ease !important; /* Smooth rotation on hover/active */
  }
  
  .ai-prompt-refresh-button:hover .refresh-icon {
    transform: rotate(90deg) !important; /* Rotate on hover */
  }
  .ai-prompt-refresh-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

/* Tooltip styling */
.refresh-tooltip {
  position: absolute !important;
  bottom: -40px !important; /* Position tooltip below the button */
  left: 50% !important;
  transform: translateX(-50%) !important;
  background-color: #333 !important; /* Dark background */
  color: #fff !important; /* White text */
  padding: 10px 15px !important; /* Larger padding for a bigger tooltip */
  border-radius: 8px !important; /* Rounded corners */
  font-size: 14px !important; /* Larger font size */
  font-weight: bold !important; /* Bold text for emphasis */
  visibility: hidden !important; /* Hide by default */
  opacity: 0 !important; /* Invisible by default */
  transition: opacity 0.3s ease !important;
  white-space: nowrap !important; /* Prevent text wrapping */
  z-index: 1000 !important; /* Ensure it appears above other elements */
}

.ai-prompt-refresh-button:hover .refresh-tooltip {
  visibility: visible !important; /* Show tooltip on hover */
  opacity: 1 !important;
}