
.home-div {
    display: flex;
    flex-direction: column;
    background-color: white;
    align-items: center;
    width: 100%;
    padding-top: 160px;
    margin-bottom: 40px;
}
.home-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap; /* Reverse order for smaller screens */
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 50px;
  gap: 40px;
  width: 1280px;
  max-width: 1400px;
}
.image-container {
    position: relative;
    border: 2px solid transparent;
    animation: border-animation 3s linear infinite;
    flex: 1 0 0px;
    height: var(--framer-aspect-ratio-supported, 660px);
    overflow: visible;
    width: 1px;

}
/* Home.css */
.visible {
  animation: fadeInUp 1s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.image-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  border: 1px solid rgba(228, 115, 32, 0); /* Initial border */
  border-radius: 5px; /* Adjust as needed */
  opacity: 0;
  transition: all 0.3s ease-in-out;
}


.image-container-inside {
    width: 1015px;
    height: var(--framer-aspect-ratio-supported, 660px);
    overflow: visible;
    border-radius: 20px;
    position: relative;
}
.image-container-inside:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background: linear-gradient(360deg, #3100f5, #ff0000);
  background-size: 300% 300%;
  clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
}

.image-container-inside.in:after {
  animation: frame-enter 1s forwards ease-in-out reverse, gradient-animation 4s ease-in-out infinite;
  border-radius: 20px;

}

/* motion */
@keyframes gradient-animation {
  0% {
    background-position: 15% 0%;
  }
  50% {
    background-position: 85% 100%;
  }
  100% {
    background-position: 15% 0%;
  }
}

@keyframes frame-enter {
  0% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), 3px calc(100% - 3px), 3px 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  25% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) calc(100% - 3px), calc(100% - 3px) 100%, 100% 100%, 100% 0%, 0% 0%);
  }
  50% {
    clip-path: polygon(0% 100%, 3px 100%, 3px 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, calc(100% - 3px) 3px, 100% 0%, 0% 0%);
  }
  75% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 3px, 3px 0%, 0% 0%);
  }
  100% {
    -webkit-clip-path: polygon(0% 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 3px 100%, 0% 100%);
  }
}
.image-container img {
    display: block;
    border-radius: inherit;
    object-position: center;
    object-fit: cover;
    image-rendering: auto;
    height: 660px;
    width: 1400px;

}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.slider-container {
    position: relative;
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 2%, rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0) 98%);
}
.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background-image: url("/public/images/gradient-background-light.png");
    background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Do not repeat the image */
      background-size: cover; /* Resize the background image to cover the entire container */
}
.home-text {
  animation: slideUp 3s ease-in-out;
  max-width: 850px;
  text-align: left;
  flex: 1 0 0px;
  width: 1px;
}


.home-text h1 {
  font-size: 60px;
  margin-bottom: 20px;
  color: black;
  text-align: left;
}
.home-text span {
    color: #00b0dc;

}

.home-text p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
  text-align: left;
}

.explore-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #00b0dc;
  color: white;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.explore-button:hover {
  background-color: #666;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image img {
  max-width: 100%;
  height: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
/* ... (previous styles) */

.home-3d-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 850px;
}
.home-section {
  position: relative;
  width: 100%;
  height: auto; /* Set the height of the home section to the viewport height */
  background-color: #f5f5f5;
}

.our-partners-section {
  background-color: none;
  padding-top: 30px;
  max-width: 1280px
}

.our-partners-title {
  position: relative;
    color: #757575;
    text-decoration: none;
    padding-bottom: 5px; /* Add space between text and underline */
    font-size: 17px;
    font-weight: 500;
    font-weight-bold: 700;
}
.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    background-color: #fff;
}
.features-section-text-div {
    max-width: 840px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.features-section-text-div p {
    max-width: 500px;
}
.features-section-title {
    font-size: 46px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    margin-bottom: 5px;
}
.features-section-span {
    font-size: 46px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    color: var(--token-4d926bee-328f-497f-a3cd-ce0b138f5bc3, rgb(156, 163, 175));
}
.partner-logo {
  width: 125px;
  height: 100px;
}
/* CustomCarousel.css */

.slick-list {
    overflow: hidden; /* Hides the overflowing elements of the slider */
    margin: 0 auto; /* Center the slider */
    height: 150px; /* Adjust based on your design */
}

.slick-track {
    display: flex;
    align-items: center;
    justify-content: start; /* Aligns slides from the start */
}
.slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px; /* Consistent height for each slide */
    width: 300px; /* Allows the slide width to adjust to content */
    max-width: 350px; /* Maximum width of each slide */
    padding: 0 10px; /* Add padding for spacing between slides */
}

.slick-slide img {
    max-width: 250px; /* Ensures image is responsive and doesn't overflow its container */
    max-height: 150px; /* Maximum height of the image */
    width: auto; /* Maintains aspect ratio */
    height: auto; /* Maintains aspect ratio */
}


.features-section-content-div {
    display: flex;
    width: 1280px;
    padding-top: 150px;
    gap: 80px;

}
.features-section-content-image-div {
    flex: 1 0;
    border-radius: 20px;

}
.features-section-content-image-div img {
    width: auto;
    max-width: 600px;
    height: auto;
    border-radius: 20px;

}
.features-section-content-text-div {
    flex: 1 0;
    max-width: 850px;
    text-align: left;

}
.features-section-content-text-div h1 {
    font-size: 48px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -2.5px;
    line-height: 110%;
    margin-bottom: 5px;
    color: black;

}
.features-section-content-text-div span {
    color: #00b0dc;
}
.features-section-content-text-div p {
    font-size: 17px;
    font-weight: 500;
    font-weight-bold: 900;
    letter-spacing: 0em;
    line-height: 140%;
    margin-bottom: 5px;

}
.features-section-content-div-in-text-div {
    display: flex;
    padding-top: 25px;
    gap: 16px;
}
.features-section-content-div-highlights {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.features-section-content-div-highlights h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -.5px;

}
.features-section-content-div-highlights p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -.5px;

}
.features-section-content-div-icon {
    width: 50px;
    height: 50px;
    align-self: flex-start;
}
.features-section-content-div-icon img {
    width: 100%;
    height: 100%;
}





.features-section-content-div-2 {
    display: flex;
    width: 1280px;
    padding-top: 150px;
    gap: 140px;
    align-items: center;


}
.features-section-content-image-div-2 {
    flex: 1 0;
    width: 1px;
    border-radius: 20px;
    max-width: 500px;
    max-height: 600px;
    overflow: hidden;


}
.features-section-content-image-div-2 img {
    display: block;
    border-radius: inherit;
    width: 100%;
    object-position: center;
    object-fit: cover;
    image-rendering: auto;

}
.features-section-content-text-div-2 {
    flex: 1 0;
    width: 1px;
    max-width: 850px;
    text-align: left;

}
.features-section-content-text-div-2 h1 {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -2px;
    line-height: 105%;
    margin-bottom: 5px;
    color: black;

}
.features-section-content-text-div-2 span {
    color: #00b0dc;
}
.features-section-content-text-div-2 p {
    font-size: 17px;
    font-weight: 500;
    font-weight-bold: 900;
    letter-spacing: 0em;
    line-height: 140%;
    margin-bottom: 5px;

}
.features-section-content-div-in-text-div-2 {
    display: flex;
    padding-top: 25px;
    gap: 16px;
}
.features-section-content-div-highlights-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.features-section-content-div-highlights-2 h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -.5px;

}
.features-section-content-div-highlights-2 p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -.5px;

}
.features-section-content-div-icon-2 {
    width: 50px;
    height: 50px;
    align-self: flex-start;
}
.features-section-content-div-icon-2 img {
    width: 100%;
    height: 100%;
}


.testimonials-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    background-color: var(--token-bf8bd38a-4575-40c0-9459-6a86668113f1, #f3f4f6);
    width: 100%;
}
.testimonials-section-text-div {
    max-width: 840px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.testimonials-section-text-div p {
    max-width: 500px;
}
.testimonials-section-title {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    margin-bottom: 5px;
    color:var(--token-2759885a-0605-4b0d-ae5f-9a938c3766f2, rgb(0, 0, 0));
}
.testimonials-section-span {
    font-size: 46px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    color: var(--token-4d926bee-328f-497f-a3cd-ce0b138f5bc3, rgb(156, 163, 175));
}
.testimonials-section-content-div {
    display: flex;
    width: 60%;
    padding-top: 75px;
    gap: 80px;

}
.testimonials-section-content-text-profile-div {
    width: 80px;
    height: var(--framer-aspect-ratio-supported, 80px);
    border-radius: 100%;
}
.testimonials-section-content-text-profile-div img {
    display: block;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
    image-rendering: auto;

}
.testimonials-section-content-text-div {
    align-content: flex-start;
    align-items: flex-start;
    background-color: #f3f4f6;
    border-radius: 24px;
    display: flex;
    flex: 1 0 0px;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 24px;
    height: min-content;
    justify-content: flex-end;
    overflow: hidden;
    padding: 40px 0;
    position: relative;
    transform: perspective(1200px);
    width: 1px;
    will-change: var(--framer-will-change-override, transform);
    text-align: left;

}
.testimonial-section-profile-name-title {
    outline: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;
    transform: none;
}

.testimonials-section-content-text-div h1 {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -2px;
    line-height: 105%;
    margin-bottom: 5px;
    color: black;

}
.testimonials-section-content-text-div span {
    color: #00b0dc;
}
.testimonials-section-content-text-div p {
    font-size: 28px;
    font-weight: 400;
    font-weight-bold: 700;
    letter-spacing: -.03em;
    line-height: 125%;
    margin-bottom: 5px;
    margin-top: 5px;
    color: var(--token-994646e0-68ea-49d7-897a-104048558f6f, rgb(79, 88, 116));
}
.testimonials-section-content-div-in-text-div {
    display: flex;
    padding-top: 25px;
    gap: 16px;
}
.testimonial-section-profile-name-title p {
    font-size: 16px;
    font-weight: 500;
    font-weight-bold: 700;
    letter-spacing: 0em;
    line-height: 140%;
    margin-bottom: 5px;
    color: var(--token-4d926bee-328f-497f-a3cd-ce0b138f5bc3, rgb(156, 163, 175));

}
.testimonials-section-content-div-highlights {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.testimonials-section-content-div-highlights h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: -.5px;

}
.testimonials-section-content-div-highlights p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -.5px;

}
.testimonials-section-content-div-icon {
    width: 50px;
    height: 50px;
    align-self: flex-start;
}
.testimonials-section-content-div-icon img {
    width: 100%;
    height: 100%;
}



.pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
    background-image: url("/public/images/gradient-background-light-flipped.png");
    background-size: 100% 95%;
    background-position: bottom;
    gap: 50px;
    background-repeat: no-repeat;
    background-color: white;
}
.pricing-period-tabs {
  display: flex;
  border-bottom: 2px solid #ccc; /* Add a subtle line under the tabs */
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: center; /* Center the tabs if you prefer */
}

.billing-cycle-option {
  padding: 10px 20px;
  border: 1px solid transparent; /* Hide border but retain layout */
  background-color: #f5f5f5;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin: 0 5px; /* Spacing between tabs */
}

.billing-cycle-option:hover {
  background-color: #eaeaea; /* Slightly darker on hover */
}

.billing-cycle-option.selected {
  color: white;
  background-color: #007bff; /* Active tab color */
  border-color: #007bff; /* Match border to background */
}

.pricing-section-title {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    margin-bottom: 5px;
}
.pricing-section-span {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    color: var(--token-4d926bee-328f-497f-a3cd-ce0b138f5bc3, rgb(156, 163, 175));
}
.pricing-section-smart {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    color: #00b0dc;
}

.pricing-cards-container {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;;
}

.pricing-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 50px 20px;
  width: 315px;
  background: linear-gradient(180deg,rgba(255,255,255,.8) 0%,rgba(249,249,250,.8) 100%);
  transition: transform 0.3s ease; /* For a smooth scaling transition */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pricing-card h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: black;
  font-size: 20px;
  font-weight: 600;
  font-weight-bold: 900;
  letter-spacing: -.5px;
  line-height: 120%;
}

.pricing-card .price {
  color: black;
  margin-top: 8px;
  font-size: 40px;
  font-weight: 600;
  font-weight-bold: 700;
  letter-spacing: -.03em;
  line-height: 125%;
  margin-bottom: 0;
}
.pricing-card .price-container {
  text-align: center; /* This will center the text of all child elements */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.pricing-card .savings {
  margin: 0;
  color: black;
  background-color: #a6f1a6;
  font-size: 13px;
  font-weight: 500; /* font-weight-bold is not a valid property, use font-weight */
  letter-spacing: -.03em;
  line-height: 125%;
  width: fit-content;
  padding: 2px 4px;
  border-radius: 5px;
}

.pricing-card .pricing-features-list {
  list-style: none;
  padding: 0;
  text-align: left;
  padding-bottom: 30px;
  padding-left: 30px;
  flex-grow: 1;

}

.h3-most-popular {
    color: #00b0dc !important;
}
.pricing-card .pricing-features-list li {
  margin-bottom: 15px;
  padding-left: 0;
  position: relative;
}
.pricing-card .pricing-features-list li::before {
  content: '';
  display: inline-block;
  width: 25px; /* Adjust based on your SVG's aspect ratio */
  height: 25px; /* Adjust based on your SVG's aspect ratio */
  background-image: url('/public/images/icons/arrow-right3.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -35px;
  top: 0;
  transform: translateY(-0.1em); /* Adjust this value as needed */
}



.popular .most-popular-tag {
  background-color: #10cfff;
  color: #156478;
  padding: 0.25rem 0.5rem;
  position: absolute;
  top: -1rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  z-index: 999;
}

.popular {
  border-color: #00b0dc;
  position: relative;
  padding: 30px;
  transform: scale(1.09); /* Slightly increase the size to make it stand out */
  z-index: 1; /* Ensure it's above the other cards */
}

.start-trial-popular {
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
    padding: 0 32px;
    border-radius: 6px;
    color: #fff;
    height: 48px;
    font-size: 15px;
    font-weight: 500;
    line-height: 155%;
    letter-spacing: .05em;
    line-height: 155%;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    width: 100%;
    font-family: "Be Vietnam Pro", "Be Vietnam Pro Placeholder", sans-serif;

}
.start-trial-popular:hover {
    box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
    transform: translateY(-2px);
}
.start-trial-btn {
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: linear-gradient(rgb(253, 253, 253) 0%, rgb(246, 246, 247) 100%) rgba(0, 0, 0, 0);
    box-shadow: rgba(17, 3, 48, 0.07) 0px 2px 6px 0px, rgba(0, 0, 0, 0.03) 0px 1px 1px 0px;
    padding: 0 32px;
    border-radius: 10px;
    border-style: solid;
    border-width: 1px;
    color: black;
    height: 48px;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    width: 100%;
    border-color: var(--token-08cb0f10-6dc5-4949-913f-dbd7168f1cf0, rgb(229, 231, 235));
    font-size: 15px;
    font-weight: 500;
    line-height: 155%;
    letter-spacing: .05em;
    align-self: flex-end;
}

.start-trial-btn:hover {
  transform: translateY(-2px);
}


*, *::before, *::after {
	box-sizing: border-box;
}

@keyframes rotate {
	100% {
		transform: rotate(1turn);
	}
}

.popular {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #00b0dc;
        border-radius: 8px;
        animation: clippath 15s infinite linear;
    }
}

@keyframes clippath {
    0%,
    100% {
        clip-path: inset(0 0 99% 0);
    }
    25% {
        clip-path: inset(0 99% 0 0);
    }
    50% {
        clip-path: inset(99% 0 0 0);
    }
    75% {
        clip-path: inset(0 0 0 99%);
    }
}
.contact-section-title {
    font-size: 52px;
    font-weight: 600;
    font-weight-bold: 900;
    letter-spacing: -1.5px;
    line-height: 110%;
    margin-bottom: 5px;

}

.calendly-inline-widget {
    overflow-y: hidden !important;
}
.features-section-new-capabilities-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 140px;
  width: 1280px;
}

.features-section-new-capabilities-image {
  flex: 1;
  max-width: 50%;
}

.features-section-new-capabilities-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.features-section-new-capabilities {
  flex: 1;
  max-width: 50%;
}

.features-section-new-capabilities h2 {
  font-size: 52px;
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: 0em;
  line-height: 140%;
}
.features-section-new-capabilities span {
  color: #00b0dc;
  letter-spacing: 0em;
  line-height: 140%;
}
.features-section-new-capabilities p {
  font-size: 17px;
  margin-bottom: 10px;
  letter-spacing: 0em;
  line-height: 140%;
}

.features-section-new-capabilities ul {
  list-style-type: disc;
  padding-left: 20px;
  letter-spacing: 0em;
  line-height: 140%;
}

.features-section-new-capabilities li {
  font-size: 17px;
  margin-bottom: 5px;
  letter-spacing: 0em;
  line-height: 140%;
}

.SBIRImageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 140px;
  width: 1280px;
}
.SBIRImageDiv img {
  width: auto;
  max-width: 1280px;
  height: auto;
  border-radius: 20px;
}



@media (max-width: 1189px) {
    .home-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap-reverse; /* Reverse order for smaller screens */
      flex-direction: column;
      width: 100%;
      padding: 0;
    }
    .home-3d-container {
      flex: 1;
      display: none;
      justify-content: center;
      align-items: center;
    }
    .our-partners-section {
        width: 100%;
    }
    .our-partners-title{
        margin: 0;
        font-size: 16px;
        padding: 0;
    }
    .slick-slide {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }

    .slick-slide img {
      width: 100px;
      height: auto !important;
    }
    .slick-list{
        height: 100px;
    }
    .slick-track{
        height: 100px;
    }
    .features-section-content-div {
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        padding-top: 40px;
    }
    .features-section-content-image-div {
        display: flex;
        width: auto;
        padding: 0 20px;
    }
    .features-section-content-image-div img {
        max-width: 100%;
    }
    .features-section-content-text-div {
        width: auto;
        padding: 0 20px;
    }
    .home-text {
        width: auto;
        padding: 0 20px;
        max-width: 100%;
    }
    .image-container {
        width: 100%;
        padding: 0 20px;
        max-width: 90%;
    }
    .home-div {
        background-color: #f5f5f5;
        padding-top: 100px;
    }
    .home-text p {
        font-size: 16px;

    }
    .features-section-text-div {
        padding: 0 20px;
    }
    .hero-section {
        gap: 100px;
        background-size: cover;
        background-position: bottom;
    }
    .image-container img {
        width: auto;
        object-position: left;
        height: auto;
        max-width: 100%;
    }
    .image-container-inside {
        width: auto;
        height: auto;
    }
    .features-section-content-text-div h1 {
        font-size: 38px;
    }
    .features-section-content-text-div p {
        font-size: 16px;
    }
    .features-section-content-div-in-text-div {
        flex-direction: column;
        padding-top: 40px;
        gap: 40px;
    }
    .features-section-content-div-2 {
        max-width: 100%;
        width: 100%;
        padding-top: 40px;
        flex-direction: column-reverse;
        gap: 40px;
    }
    .features-section-content-image-div-2 {
        display: flex;
        width: auto;
        padding: 0 20px;
    }
    .features-section-content-image-div-2 img {
        max-width: 100%;
    }
    .features-section-content-text-div-2 {
        width: auto;
        padding: 0 20px;
    }
    .features-section-content-text-div-2 h1 {
        font-size: 38px;
    }
    .features-section-content-text-div-2 p {
        font-size: 16px;
    }
    .testimonials-section {
        padding: 60px 20px;
    }
    .testimonials-section-title {
        font-size: 40px;
        letter-spacing: -1px;
        line-height: 115%;
    }
    .testimonials-section-content-div {
        flex-direction: column;
        padding-top: 40px;
        gap: 40px;
        width: 100%;

    }
    .testimonials-section-content-text-div {
        width: 100%;
        padding-bottom: 0;
    }
    .features-section {
        padding-bottom: 60px;
        padding-top: 40px;
    }

    .SBIRImageDiv,
    .features-section-new-capabilities-wrapper {
        width: auto;
        padding: 0 20px;
        flex-direction: column;
    }

    .features-section-new-capabilities-image img,
    .features-section-new-capabilities-image,
    .features-section-new-capabilities,
    .SBIRImageDiv img {
        max-width: 100%;
    }

    .features-section-new-capabilities-wrapper {
        gap: 0px;
        margin-top: 40px;
    }

    .features-section-new-capabilities h2,
    .features-section-new-capabilities span {
        font-size: 38px;
    }

    .features-section-new-capabilities p,
    .features-section-new-capabilities li {
        font-size: 16px;
    }

    .SBIRImageDiv {
        margin-top: 40px;
    }
    .features-section-p {
        font-size: 16px;
        padding: 0 20px;
        text-align: center;
    }
    .features-section-title {
        font-size: 35px;
    }
    .features-section-span {
        font-size: 25px;
    }





    .pricing-section {
        padding: 60px 20px;
        background-image: url("/public/images/gradient-background-light-flipped.png");
        background-size: cover;
        background-position: bottom;

    }
    .pricing-cards-container {
      flex-direction: column;
      align-items: center;
    }

    .pricing-card {
      margin-bottom: 1rem;
      transform: none;
    }

    .pricing-section-title {
        font-size: 40px;
        font-weight: 600;
        font-weight-bold: 900;
        letter-spacing: -1.5px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .pricing-section-span {
        font-size: 40px;
        font-weight: 600;
        font-weight-bold: 900;
        letter-spacing: -1.5px;
        line-height: 110%;
        margin-bottom: 5px;
    }

    .pricing-section-smart {
        font-size: 40px;
        font-weight: 600;
        font-weight-bold: 900;
        letter-spacing: -1.5px;
        line-height: 110%;
        color: #00b0dc;
    }
    .contact-section-title {
        font-size: 40px;
        font-weight: 600;
        font-weight-bold: 900;
        letter-spacing: -1.5px;
        line-height: 110%;
        margin-bottom: 5px;
        background-color: #fff;
    }
    .calendly-inline-widget {
        height: 1100px !important;
        overflow-y: hidden !important;
        background-color: #fff;
    }
    .hPAsHHd0FN8065UiP705.bPjMK2MqWr9_ZWcyR8AJ {
        display: none;
    }
    .pricing-section-text-div-calendar {
      margin-bottom: 100px;
    }
}



/* =========== How GovEx AI Works Section =========== */
.how-it-works-section {
  width: 100%;
  padding: 80px 20px;
  background-color: #ffffff;
  text-align: center;
}

.how-it-works-title {
  font-size: 42px;
  font-weight: 700;
  margin-bottom: 16px;
  color: #000;
}

.how-it-works-intro {
  font-size: 16px;
  line-height: 1.5;
  max-width: 700px;
  color: #666;
  margin: 0 auto 50px auto;
}

/* Container: steps on left, preview on right */
.how-it-works-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

/* LEFT: Vertical Steps */
.how-it-works-steps {
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.how-it-works-step {
  background-color: #f9fafb;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  text-align: left;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
}

.how-it-works-step h3 {
  font-size: 18px;
  margin-bottom: 8px;
  color: #000;
}

.how-it-works-step p {
  font-size: 15px;
  line-height: 1.4;
  color: #666;
  margin: 0;
}

/* Hover effect for all steps */
.how-it-works-step:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
}

/* Highlight the actively selected step */
.active-step {
  background-color: #ffffff;        /* Slightly different color if desired */
  border-left: 4px solid #00b0dc;  /* Accent color on the left */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

/* RIGHT: GIF Previews */
.how-it-works-preview {
  position: relative;
  flex: 1;
  min-height: 400px;
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
}

/* Each GIF: stacked absolutely */
.gif-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none; /* hidden by default */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Show the active step's GIF */
.gif-preview.active {
  display: block;
}

/* =========== Responsive (Mobile, etc.) =========== */
@media (max-width: 900px) {
  .how-it-works-container {
    flex-direction: column;
    align-items: center;
  }

  .how-it-works-steps {
    width: 100%;
    order: 2;
  }

  .how-it-works-preview {
    order: 1;
    width: 100%;
    min-height: 300px;
    margin-bottom: 20px;
  }
}


  
  
  /* ====================================
     USE CASES SECTION
     ==================================== */
  .use-cases-section {
    width: 100%;
    background-color: #f9fafb; /* Light gray background */
    padding: 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .use-cases-title {
    font-size: 52px;
    font-weight: 700;
    letter-spacing: -1.5px;
    line-height: 110%;
    margin-bottom: 10px;
    color: #000;
    text-align: center;
  }
  
  .use-cases-intro {
    max-width: 700px;
    text-align: center;
    font-size: 18px;
    line-height: 160%;
    color: #666;
    margin-bottom: 50px;
  }
  
  .use-cases-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 40px;
    max-width: 1200px;
    width: 100%;
  }
  
  .use-case-card {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.06);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .use-case-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  }
  
  .use-case-card .use-case-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .use-case-card .use-case-icon img {
    width: 100%;
    height: auto;
  }
  
  .use-case-card h3 {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
  }
  
  .use-case-card p {
    font-size: 16px;
    line-height: 150%;
    color: #666;
  }
  
  
  /* =========================================
   FAQ SECTION
   ========================================= */
.faq-section {
  width: 100%;
  background-color: #ffffff;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-title {
  font-size: 52px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 110%;
  margin-bottom: 20px;
  color: #000;
  text-align: center;
}

.faq-subtitle {
  font-size: 24px;
  color: #666;
  text-align: center;
  max-width: 700px;
  margin-bottom: 50px;
  line-height: 150%;
}

/* Container for all FAQ items */
.faq-list {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

/* Single FAQ item container */
.faq-item {
  background-color: #f9fafb;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px 30px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.06);
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

/* On hover or active state, slight box-shadow change */
.faq-item:hover {
  background-color: #f6f8fa;
  box-shadow: 0 6px 14px rgba(0,0,0,0.06);
}

/* FAQ Question container with toggle icon */
.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-question h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: #000;
}

.faq-question .faq-toggle-icon {
  font-size: 24px;
  color: #00b0dc;
  transition: transform 0.3s ease;
}

/* FAQ Answer text hidden by default. We'll animate open/close with JavaScript. */
.faq-answer {
  font-size: 16px;
  color: #666;
  line-height: 150%;
  margin-top: 10px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

/* When the item is expanded, show the answer fully */
.faq-item.active .faq-answer {
  max-height: 300px; /* Large enough for typical text length. Adjust as needed. */
  opacity: 1;
}

.faq-item.active .faq-toggle-icon {
  transform: rotate(180deg);
}

/* Create a small colored border indicator on the left side of the FAQ item */
.faq-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background: linear-gradient(#00b0dc, #ff00ad);
  border-radius: 16px 0 0 16px;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.faq-item:hover::before {
  opacity: 0.4;
}

/* ======= Responsive Adjustments ======= */
@media (max-width: 1189px) {
  .faq-section {
    padding: 60px 20px;
  }

  .faq-title {
    font-size: 40px;
  }

  .faq-subtitle {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .faq-list {
    max-width: 100%;
  }

  .faq-item {
    padding: 20px;
  }
}

  
  /* ====================================
     MEDIA QUERIES
     (Adjust as needed for responsiveness)
     ==================================== */
  @media (max-width: 1189px) {
    .how-it-works-section,
    .use-cases-section,
    .faq-section {
      padding: 60px 20px;
    }
  
    .how-it-works-title,
    .use-cases-title,
    .faq-title {
      font-size: 40px;
    }
  
    .how-it-works-intro,
    .use-cases-intro,
    .faq-intro {
      font-size: 16px;
    }
  
    .how-it-works-steps {
      flex-direction: column;
      gap: 40px;
    }
  
    .use-cases-grid {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  
    .use-case-card {
      margin-bottom: 20px;
    }
  
    .faq-list {
      max-width: 100%;
    }
  }
  