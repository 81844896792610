.step-indicator-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 0px 0;
  width: 100%;
}

.step-indicator-container:before {
  content: '';
  position: absolute;
  top: 30%;
  left: 0;
  height: 4px;
  background-color: #ddd; /* Base progress bar color */
  width: 100%;
  z-index: 0;
  transform: translateY(-50%);
}

.progress-bar {
  position: absolute;
  top: 30%;
  left: 0;
  height: 4px;
  background-color: #4CAF50; /* Active progress bar color */
  z-index: 1;
  transform: translateY(-50%);
  transition: width 0.5s ease; /* Smooth transition for width change */
}

.step {
  position: relative;
  z-index: 2; /* Above progress bars */
  text-align: center;
  flex: 1;
}

.step-icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #ddd;
  color: #333;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.step-icon.completed {
  background-color: #4CAF50;
  border-color: #4CAF50;
  color: white;
}

.step-label {
  font-size: 14px;
  color: #333;
  font-weight: 400;
}

.step.active .step-icon {
  border-color: #00b0dc;
  color: #00b0dc;
}
.step-label {
  font-size: 14px;
  color: #666; /* Dark grey color */
  text-align: center;
  padding: 0;
}

.step.active .step-label {
  color: #007bff; /* Blue color for the text of the active step */
  font-weight: 500;
  
}


.step.clickable {
  color: #007bff; /* Example styling; adjust as needed */
}

.step:not(.clickable) {
  .step-icon,
  .step-label {
    color: #ccc;
  }
  color: #ccc; /* Dim non-clickable steps for visual indication */
}
