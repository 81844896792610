/* Referral page container */
.referral-container {
    text-align: center;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
}

/* Referral page title */
.referral-title {
    color: #2a2a2a;
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: bold;
}

/* Referral description */
.referral-description {
    font-size: 18px;
    color: #4a4a4a;
    margin-bottom: 35px;
    line-height: 1.8;
}

/* Referral steps container */
.referral-steps {
    margin-bottom: 30px;
    border-radius: 8px;
}

/* Individual step styling */
.referral-step {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
    border-radius: 8px; /* Keeps the rounded corners */
}
.copy-confirmation {
    position: absolute;
    bottom: 40%; /* Position it above the container */
    left: 50%; /* Start from the middle */
    transform: translateX(-50%); /* Center it horizontally */
    color: green; /* Text color */
    margin-bottom: 5px; /* Distance from the button */
    padding: 5px 10px; /* Some padding around the text */
    white-space: nowrap; /* Ensure it doesn't wrap */
}
.referral-link-container {
    position: relative;
}


/* Step title */
.step-title {
    color: #4A90E2;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}

/* Step description */
.step-description {
    font-size: 16px;
    color: #5a5a5a;
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: left;
}
.referral-link-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* Button for copying the referral link */
.copy-link-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.copy-link-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* Note at the bottom of the steps */
.referral-note {
    font-size: 17px;
    color: #707070;
    line-height: 1.6;
    margin-top: 20px;
}

/* Referral link input styling */
.referral-link-input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: calc(50% - 24px); /* Adjust based on padding */
    text-align: center;
    background-color: #f3f3f3;
    color: #333;
    font-size: 16px;
}

/* Responsive Design */
@media (max-width: 600px) {
    .referral-container {
        margin: 20px;
        padding: 15px;
    }
    .referral-step {
        margin-bottom: 35px;
    }
    .copy-link-button {
        width: 100%;
        padding: 15px 0; /* Full-width button for easier clicking on mobile */
    }
}
