/* ArticlePreview.css */
.article-preview {
  border-radius: 8px;  /* Softer corners */
  width: calc(30%); /* Adjusting width to account for margin */
  height: 250px;  /* Flexible height based on content */
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;  /* Smooth transitions for interaction */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-preview:hover {
  transform: translateY(-5px);  /* Slight lift effect on hover */
}



.preview-link {
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.preview-image {
  width: 100%; /* Ensure image width is fixed to the container width */
  height: 175px; /* Fixed height for images */
  border-radius: 5px;
  margin-bottom: 10px;
  object-fit: cover; /* Ensures the image covers the area, may crop if necessary */
  cursor: pointer;
}

.preview-title {
  font-size: 15px;
  margin-bottom: 10px;
  width: 100%;
  text-align: left;
  font-weight: 600;

}

.preview-excerpt {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
  display: none;
}

.read-more-btn {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: none;
}
.publication-date {
    font-size: 10px;
    text-align: left;
    align-self: flex-start;
}
.read-more-btn:hover {
  background-color: #0056b3;
}
@media (max-width: 768px) {
    .article-preview {
        margin: 10px;
        width: 100%;
    }
}