.guideContainer {
  font-family: Arial, sans-serif;
  text-align: left;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.guideContainer h2 {
  color: #007bff;
  text-align: center;
}

.guideSteps {
  list-style: none;
  padding: 0;
  padding-top: 50px;
}

.guideSteps li {
  margin-bottom: 20px;
}

.guideSteps h3 {
  margin-bottom: 5px;
  color: #0056b3;
}

.guideSteps p, .guideSteps ul {
  margin-left: 20px;
}

.guideSteps ul {
  list-style-type: disc;
}

.guideSteps li ul li {
  margin-bottom: 5px;
}
