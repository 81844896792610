/* Existing sidebar styles */
.sidebar {
  /* Existing sidebar styles */
  background-color: #141318;
  flex-shrink: 0; /* Prevent sidebar from shrinking */
  width: 200px;
  transition: width 1s ease-in-out;
  display: flex;
  flex-direction: column;

  /* ...other existing styles... */
}
/* Style for collapsed sidebar */
.sidebar.collapsed {
  width: 50px; /* Adjust the width for collapsed state */
  transition: width 1s ease-in-out;
  align-items: center;
}

/* Style for sidebar buttons */
.sidebar button {
  display: block;
  width: 100%;
  transition: opacity 0.3s ease-in-out, visibility 3s ease-in-out;
  /* Add other button styles as needed */
}

/* Hide all buttons except the toggle button when sidebar is collapsed */
.sidebar.collapsed button:not(.dashboard-sidebar-button1) {
  display: none !important;
}

/* Sidebar.css */
.user-info {
  padding-left: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  transition: all 3s ease;
}

.username i.fas {
    color: #007bff; /* Or any color that matches your design */
    font-size: 0.8em; /* Adjust size as needed */
    margin-left: 5px; /* Space between username and icon */
}

.user-info:hover .username i.fas {
    color: #0056b3; /* Darker color on hover for feedback */
}
.username:hover,
.email:hover,
.user-info:hover {
    cursor: pointer;
}

/* Sidebar.css */
.company-logo-container {
  display: flex;
  justify-content: center; /* Center the logo in the sidebar */
  padding-bottom: 25px;
}

.company-logo {
  max-width: 100%; /* Make sure it's responsive and fits the container */
  height: auto; /* Maintain aspect ratio */
}
.company-logo-collapsed {
  /* Styles for the collapsed logo */
  width: 50px; /* Smaller width for the collapsed state */
  height: auto;
}
.icon {
  width: 20px !important; /* Adjust width as needed */
  height: 20px !important; /* Adjust height as needed */
  transition: transform 1s ease-in-out !important;
  
  display: flex !important;
  justify-content: center !important; /* Center horizontally */
  align-items: center !important; /* Center vertically */

}
.toggle-button:not(.toggle-button-collapsed) .icon {
    transform: rotate(180deg) !important; /* Rotate icon when sidebar is not collapsed */
}

.username {
  color: #cccccc;
  display: flex;
  align-items: center;
}

.email {
  color: #999999;
  font-size: 14px;
}

.sidebar-logout-button {
  background-color: #141318 !important;
  color: #FA7575 !important;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  padding: 0 !important;
  margin: 0 !important;
  width: auto !important;
  /* Additional styles */
}


/* Style for toggle button */
.sidebar-button {
  display: flex;
  align-items: center;
  /* Add other button styling as needed */
}
/* Hide button text when sidebar is collapsed */
.sidebar.collapsed .button-text {
  display: none;
}
/* Sidebar.css */
.hide-info {
  display: none !important;
}

/* You can also target the children of .hide-info directly if needed */
.hide-info .user-info,
.hide-info .sidebar-logout-button {
  display: none;
}

.toggle-button {
  position: fixed; /* Fixed position to stay in place during scroll */
  top: 50%;; /* Adjust as per design */
  left: 30px; /* Adjust if sidebar is on the left side */
  z-index: 100 !important; /* Ensure it's above other elements */
  transition: left 1s ease-in-out !important;
  margin: 0 !important;
  border-radius: 25px !important;
  background-color: #00b0dc !important;
}
/* Positioning when the sidebar is collapsed */
.toggle-button-collapsed {
  left: 180px; /* Or the width of the collapsed sidebar */
  transition: left 1s ease-in-out !important;
  margin: 0 !important;
  background-color: #00b0dc !important;
}
.sidebar-content {
  flex: 1; /* This will take up all available space */
  padding: 10px;
  max-height: 40%;
}

.sidebar-footer-div {
  margin-top: auto; /* This pushes the footer to the bottom */
  max-width: 200px;
}
.sidebar-footer-div.collapsed {
    display: none;
}
.logout-section {
    display: flex;
    align-items: center;
    margin-bottom: auto;
    max-width: 200px;
    justify-content: space-around;
    padding-bottom: 5px;
    gap: 20px;
}

.additional-info-footer {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    max-width: 200px;
    flex-direction: column;
}
.company-name-div {
  color: #f5f5f5;
  font-size: 25px;
  padding-bottom: 20px;
  position: relative;
  display: inline-block;
  background-color: transparent;
}

.company-name-div::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px; /* Height of the underline */
  bottom: 15px; /* Adjust distance from text */
  left: 0;
  background-image: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(0,176,220,1) 7%, rgba(13,144,189,0.874) 38%, rgba(255,255,255,1) 100%);
}

/* Custom dropdown styling */
.custom-dropdown {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.dropdown-header {
  background-color: transparent;
  padding: 10px 0;
  font-size: 25px;
  color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  width: 100%;
}

.custom-arrow {
  margin-left: 10px;
  font-size: 14px;
  color: #f5f5f5;
}

/* Dropdown list */
.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  border: 1px solid #ccc;
}

.dropdown-item {
  padding: 10px;
  font-size: 20px;
  color: #f5f5f5;
  background-color: #333;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #444;
}

.dropdown-item.selected {
  background-color: #555;
}


.number-proposals-div {
    display: flex;
    align-items: center;
    max-width: 200px;
    width: 200px;
    justify-content: space-between;
}
.number-proposals-text {
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
    max-width: 75%;
    font-weight: 300;
    color: #f5f5f5;
    font-size: 15px;
}
.subscription-tier-text {
    padding-left: 10px;
    text-align: left;
    overflow: hidden;
    max-width: 75%;
    color: #f5f5f5;
    font-weight: 300;
    font-size: 15px;
}
.number-proposals-number {
    max-width: 25%;
    width: 20%;
    font-weight: 300;
    color: #f5f5f5;
    font-size: 15px;
}
.subscription-tier-value {
    max-width: 40%;
    width: fit-content;
    color: #f5f5f5;
    padding-right: 10px;
    font-size: 15px;
    text-align: center;
    font-weight: 300;
}
.sidebar .dashboard-sidebar-button1,
.sidebar .dashboard-sidebar-button {
    margin: 0 0 10px 0; /* Spacing at the bottom of each button */
    padding: 7px; /* Increased padding for better touch target size */
    font-family: Arial, sans-serif; /* Improved readability */
    font-size: 14px; /* Adjusted font size */
    color: white; /* Font color */
    background-color: #141318; /* Subtle background color */
    border-radius: 5px; /* Rounded corners */
    text-align: left;
    display: flex;
    transition: all 4s ease; /* Smooth transition for hover effects */
    align-items: center;
    justify-content: flex-start;
}

.sidebar .dashboard-sidebar-button:hover,
.sidebar .dashboard-sidebar-button1:hover {
    background-color: #2B2B2F; /* Change background color on hover */
    border-color: #ccc; /* Darken border on hover */
}

.sidebar .dashboard-sidebar-button:active,
.sidebar .dashboard-sidebar-button1:active {
    background-color: #ddd; /* Background color for active state */
    color: #222; /* Text color for active state */
    border-color: #bbb; /* Border color for active state */
}

.dashboard-sidebar-button1.selected {
  background-color: #2B2B2F; /* Example green background for the selected tab */
  color: white; /* White text color for better contrast */
}
.dashboard-sidebar-button-HIW {
    margin: 0 0 10px 0 !important; /* Spacing at the bottom of each button */
    padding: 25px !important; /* Increased padding for better touch target size */
    font-family: Arial, sans-serif; /* Improved readability */
    font-size: 14px; /* Adjusted font size */
    color: white !important; /* Font color */
    background-color: #141318 !important; /* Subtle background color */
    border-radius: 5px !important; /* Rounded corners */
    text-align: left;
    display: flex !important;
    transition: all 3s ease !important; /* Smooth transition for hover effects */
    align-items: center;
    justify-content: center;
}
.dashboard-sidebar-button-Referral {
    margin: 0 !important; /* Spacing at the bottom of each button */
    padding: 25px !important; /* Increased padding for better touch target size */
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-family: Arial, sans-serif; /* Improved readability */
    font-size: 14px; /* Adjusted font size */
    color: white !important; /* Font color */
    background-color: #141318 !important; /* Subtle background color */
    border-radius: 5px !important; /* Rounded corners */
    text-align: left;
    display: flex !important;
    transition: all 3s ease !important; /* Smooth transition for hover effects */
    align-items: center;
    justify-content: center;
}
.disabled-step,
.sidebar .dashboard-sidebar-button1.disabled-step:hover {
  cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
  background-color: #141318; /* Keep the background color consistent on hover */
  color: #ccc; /* Ensure the text color remains grey */
}

/* Disable hover effect for disabled buttons */
.sidebar .dashboard-sidebar-button1.disabled-step:hover {
  background-color: #141318; /* Reiterate background color for clarity */
}
/* Sidebar.css */

/* Styling for disabled buttons */
.sidebar-button-disabled {
  background-color: #141318 !important;
  color: #888;
  cursor: not-allowed;
  position: relative;
}
.sidebar-button-disabled:hover {
  background-color: #2B2B2F !important;
}
.sidebar-button-disabled .icon {
  opacity: 0.5;
}

/* Styling for the "Coming Soon" banner */
.coming-soon-banner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffcc00;
  color: #000;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 0 4px 4px 0;
}
