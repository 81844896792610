/* Enhanced styling for HowToUseGovExAISBIR.js */

.guideContainerSBIR {
  font-family: Arial, sans-serif;
  text-align: left;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.guideTitle {
  color: #28a745;
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  border-bottom: 2px solid #28a745;
  padding-bottom: 10px;
}

.guideStepsSBIR {
  list-style: none;
  padding: 0;
}

.guideStepItem {
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.guideStepItem:hover {
  transform: translateY(-5px);
}

.guideStepTitle {
  margin-bottom: 15px;
  font-size: 1.5em;
  color: #218838;
  display: flex;
  align-items: center;
}

.guideStepTitle::before {
  content: "🔎";
  margin-right: 10px;
}

.guideStepItem p {
  margin-bottom: 15px;
  font-size: 1.1em;
  line-height: 1.6;
}

.guideActionList {
  list-style-type: disc;
  padding-left: 20px;
}

.guideActionList li {
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #343a40;
}

.guideActionList li::before {
  content: "👉";
  margin-right: 10px;
  color: #218838;
}

.guideActionList li strong {
  color: #218838;
}
/* Styling for the demo link */

.demoLinkContainer {
  text-align: center;
  margin-bottom: 20px;
}

.demoLink {
  display: inline-block;
  padding: 10px 20px;
  background-color: #17a2b8;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.demoLink:hover {
  background-color: #138496;
}
