.referral-landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    padding-top: 0px;
    height: 100vh;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  }
  .animated-border {
    position: relative;
  }
  
  .animated-border::before {
    content: "";
    position: absolute;
    
    margin-top: 0px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid #00b0dc; /* Adjust border color and thickness */
    border-radius: 8px; /* Adjust border radius as needed */
    animation: clippath 15s infinite linear;
    pointer-events: none; /* Ensures the animation doesn't block interaction with inner elements */
  }
  
  @keyframes clippath {
    0%,
    100% {
      clip-path: inset(0 0 99% 0);
    }
    25% {
      clip-path: inset(0 99% 0 0);
    }
    50% {
      clip-path: inset(99% 0 0 0);
    }
    75% {
      clip-path: inset(0 0 0 99%);
    }
  }
  
  
  .pricing-section {
    background: #ffffff;
    border-radius: 12px;
    padding: 40px;
    max-width: 1200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    width: 100%;
    gap: 20px;
  }
  
  
  .pricing-section-title {
    font-weight: bold;
    color: #333;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  
  .pricing-section-span {
    font-size: 24px;
    color: #555;
  }
  .tooltip-icon {
    font-weight: bold;
    margin-left: 5px;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 50%;
    padding: 2px 6px;
    position: relative;
  }
  
  .tooltip-content {
    visibility: hidden;
    background-color: #f9f9f9;
    color: #000;
    text-align: left;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 40px; /* Adjust this value to position the tooltip above the icon */
    left: 130%;
    transform: translateX(-50%);
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .tooltip-content::after {
    content: '';
    position: absolute;
    top: 100%; /* Arrow at the bottom */
    left: 46%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #f9f9f9 transparent transparent transparent;
  }
  
  .tooltip-icon:hover .tooltip-content {
    visibility: visible;
  }
  
  
  .pricing-sliders-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 20px;
  }
  
  .slider-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
  }
  
  .slider-pricing-container label {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .slider-pricing-container input[type="range"] {
    width: 100%;
    margin: 10px 0;
  }
  
  .slider-pricing-container span {
    font-size: 16px;
    color: #555;
    margin-top: 5px;
  }
  
  
  .pricing-summary {
    text-align: center;
  }
  
  .pricing-summary h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .pricing-summary p {
    font-size: 20px;
    color: #007bff;
    font-weight: bold;
    text-align: center;
  }
  
  .checkout-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 12px 30px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .checkout-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pricing-section {
      padding: 20px;
    }
  
    .slider-container {
      width: 100%;
    }
  
    .pricing-section-title {
      font-size: 28px;
    }
  
    .pricing-summary h3 {
      font-size: 22px;
    }
  
    .checkout-button {
      padding: 10px 25px;
      font-size: 14px;
    }
    .tooltip-content {
      width: 250px;
      left: 100%; /* Adjust positioning to fit on small screens */
      bottom: 50px; /* Increase bottom margin for mobile devices */
    }
}

  


/* Radio Button Group for DB Tiers */
.db-radio-group {
  display: flex;          /* horizontally side by side */
  justify-content: center;
  gap: 20px;              /* space between radio options */
  margin: 10px 0;
}

.db-radio-option {
  display: flex;
  align-items: center;
  gap: 6px;               /* space between radio input and label */
  cursor: pointer;
}

.db-radio-input {
  accent-color: #007bff;  /* modern browser property to color the radio button */
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.db-radio-label {
  font-size: 16px;
  color: #333;
}
/* Container for the entire "Add Grant Matching" row */
.grant-matching-module {
  display: flex;            /* Place items side by side */
  align-items: center;      /* Vertically center */
  gap: 10px;                /* Space between elements */
  justify-content: space-evenly;
  margin: 25px 0;           /* Add some vertical spacing */
}

/* Label text */
.grant-matching-label {
  font-size: 18px;          /* Slightly larger text */
  color: #333;              /* Dark text color */
  cursor: pointer;          /* Cursor shows clickable */
}

/* The checkbox itself */
.grant-matching-checkbox {
  width: 20px;              /* Make the checkbox a bit bigger */
  height: 20px;
  accent-color: #007bff;    /* Modern CSS property for checkbox color */
  cursor: pointer;
}

/* The price text when checkbox is checked */
.grant-matching-price {
  font-size: 16px;
  color: #555;
  font-weight: normal;
}
/* Wrapper for each "module" or block (DB tier, Grant tokens, etc.) */
.pricing-module {
  border: 1px solid #ddd;     /* Light grey border */
  border-radius: 8px;         /* Rounded corners */
  padding: 20px;              /* Space inside the box */
  margin-bottom: 20px;        /* Space below each module */
  background-color: #fafafa;  /* Subtle background color */
}

/* Headings in each module */
.module-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;        /* Space under the heading */
  color: #444;
  margin-top: 5px;
}

/* A row for the grant matching content */
.grant-matching-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;                /* Space between label, checkbox, price */
}

/* If you want the entire row horizontally spaced: 
   .grant-matching-row {
     display: flex;
     justify-content: space-between;
     align-items: center;
   }
*/

.grant-matching-label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.grant-matching-checkbox {
  accent-color: #007bff;    /* Modern CSS property for checkbox color */
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.grant-matching-price {
  font-size: 16px;
  font-weight: normal;
  color: #555;
}

.pricing-period-tabs {
  margin-top: 0;
  margin-bottom: 50px;
  width: auto;
  justify-self: center;
}

.grant-token-slider {
  width: 50%;
}
.grant-token-price-p {
  font-size: 16px;
  color: #555;
  font-weight: normal;
  text-align: center;
}
.grant-token-count-p-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 50%;
}
@media (max-width: 768px) {
  .grant-token-count-p-container {
    width: 75%;
  }
  .grant-token-slider {
    width: 75%;
  }
}
.wizard-step {
  margin: 30px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wizard-step h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #333;
}

.wizard-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.back-button,
.next-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-button:hover,
.next-button:hover {
  background-color: #0056b3;
}


.grant-token-count-p {
  font-size: 16px;
  color: #555;
  font-weight: normal;
  text-align: center;
}
/* Styles for Step 3: Matching Module */

/* The overall container for Step 3 */
.matching-module-step {
  text-align: center;
  margin: 20px auto;
}

/* Step Title */
.matching-module-title {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
}

/* Short descriptive paragraph */
.matching-module-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  max-width: 600px;
  margin: 0 auto 20px; /* center, add spacing */
}

/* A row for the label, checkbox, and price */
.matching-module-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;   /* space between items */
  margin-bottom: 20px;
}

/* Label next to the checkbox */
.grant-matching-label {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

/* The checkbox itself */
.grant-matching-checkbox {
  accent-color: #007bff;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* The optional price text */
.grant-matching-price {
  font-size: 16px;
  color: #555;
  font-weight: normal;
}

.final-total-p {
  font-size: 16px;
  color: #555;
  display: flex;
  justify-content: space-between;
  font-weight: normal;
  text-align: left;
  margin-top: 20px;
  border-top: 2px solid #ccc;
  padding-top: 10px;
}

.summary-h3 {
  font-size: 30px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}



/* Each row in the summary: DB Tier, Tokens, Matching, etc. */
.summary-row {
  display: flex;               /* side-by-side layout */
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;              /* spacing between rows */
  font-size: 16px;
  color: #555;
  width: 75%;
  justify-self: center;
}

/* The label on the left */
.summary-label {
  font-weight: 500;
  color: #333;
}

/* The numeric or yes/no value on the right */
.summary-value {
  text-align: right;
}

/* Optional style for the final total row */
.final-total-row {
  font-size: 18px;             /* make final total a bit bigger */
  font-weight: 600;
  color: #007bff;
  margin-top: 20px;
  border-top: 2px solid #ccc;
  padding-top: 10px;
}
