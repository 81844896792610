/* Footer.css */

/* Footer container styles */
.wrapper {
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
  align-items: center;
  flex-direction: column;

}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  max-width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 200px;
  height: auto;
  margin-right: 10px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end; /* Adjust this line to align the links to the right */
}

.footer-links ul li {
  margin-left: 20px; /* Adjust margin to separate links */
  position: relative;
  font-size: 18px;
}

.footer-links ul li a {
  color: black;
  text-decoration: none;
}

.footer-links ul li a:hover::before {
  width: 100%;
}

/* Submenu styles */
.footer .submenu {
  list-style: none;
  display: flex;
  position: absolute;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
}

.footer .mainNavLink:hover .submenu {
  opacity: 1;
  visibility: visible;
}

.footer .submenu li {
  padding: 5px;
  width: 100%;
  text-align: left;
}

.footer .submenu a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 5px;
  position: relative;
}
.footer-copyright {
    color: black;
}
.footer .submenu .subLinkText {
  position: relative;
}

.subLinkText {
  padding-bottom: 5px;
}

.footer .submenu .subLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #666;
  transition: width 0.5s ease-in-out;
}

.footer .submenu a:hover .subLinkText::before {
  width: 100%;
}

.footer .submenu a:hover .subLinkText {
  color: black;
  transition: color 0.5s ease-in-out;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .logo {
    justify-content: center;
  }

  .logo img {
    width: 150px;
    height: auto;
  }
  .footer-links {
    display: none;
  }

  .footer-links ul {
    display: none;
    flex-direction: column;
    background-color: black;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 52px;
    left: 0;
    transition: all 0.3s ease;
    padding: 10px 0;
    z-index: -1111;
  }

  ul.active {
    display: block;
    z-index: 999;
  }

  li {
    margin: 5px 0;
  }

  .menu-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
  .footer {
    width: 100%;
    justify-content: center;
    padding-top: 25px;
    padding: 0;
  }
}

