/* Products.css */
.products-page {
  /* Add any overall styling for the products page here */
}

.overview-section {
  background-image: url('/public/images/product-background.jpg');
  background-size: cover;
  background-position: center;
  color: #fff; /* Set text color to be visible against the background */
  padding: 40px 0; /* Adjust padding as needed */
  text-align: center;
}

.overview-content {
  width: 50%;
  max-width: 40%;
  padding-left: 50px;
  text-align: left;
}

.overview-content h1 {
  font-size: 45px;
  margin-bottom: 20px;
}

.overview-content p {
  font-size: 25px;
  line-height: 1.6;
}

.quick-look-section {
  padding: 60px 0;
  background-color: #fff;
}

.quick-look-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 75px;
  flex-wrap: wrap;
  animation: slideUp 2s ease-in-out; /* Apply the slideUp animation */
}
.animate-slide-up {
  animation: slideUp 0.5s ease-in-out;
}
.quick-look-image img {
  width: 100%;
  max-width: 500px;
  height: auto;
}
.quick-look-image {
  padding-left: 20px;
  padding-right: 20px;
}

.quick-look-text {
  flex: 1;
  text-align: left;
  padding-left: 20px;
    padding-right: 20px;
}

.quick-look-text h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #226389;
}

.quick-look-text p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}
.quick-look-text h2:hover {
    color: #666;
    transition: color 0.3s ease-in-out;
}

.learn-more-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #00b0dc;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.learn-more-button:hover {
  background-color: #666 !important;
  color: white;
}
.quick-look-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  padding-top: 40px;
}

.quick-look-step {
  text-align: center;
  justify-content: center;
  width: 250px;
}

.quick-look-step img {
  max-width: 50px;
}

.quick-look-step p {
  font-size: 20px;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-tabs {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.product-tab {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
  margin-right: 10px;
  transition: background-color 0.3s ease-in-out;
}

.product-tab:hover {
  background-color: #666;
  color: white;
}

.product-tab.active {
  background-color: #226389;
  color: white;
  border: 1px solid;
}

.product-tab.active:hover {
  background-color: #666;
}

@media screen and (max-width: 768px) {
    .overview-content h1 {
        font-size: 25px;
        text-align: center;
    }
    .overview-content p {
        font-size: 15px;
        text-align: center;
    }
    .overview-content{
        width: 100%;
        max-width: 100%;
        padding-left: 0;
    }
    .quick-look-text{
        text-align: center;
    }
}



