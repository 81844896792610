.product-highlight {
  background-color: #f5f5f5;
  padding: 40px;
}

.product-highlight-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.product-highlight-image img {
  max-width: 650px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.product-highlight-image {
  max-width: 50%;
  width: 50%;
  max-height: 50%;
}

.product-highlight-text {
  flex: 1;
  text-align: left;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.product-highlight h2 {
  font-size: 24px;
  color: #66aaff;
}

.product-highlight h1 {
  font-size: 36px;
  line-height: 1.2;
  color: black;
}

.product-highlight p {
  font-size: 18px;
  color: black;
  margin-bottom: 15px;
  line-height: 1.6;
}

.product-highlight-cta {
  margin-top: 20px;
  width: 100%;
}

.explore-button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.explore-button:hover {
  background-color: #666;
}
.product-highlight-title {
  text-align: center;
  margin-bottom: 40px;
  justify-content: center;
  width: 100%;
}

.product-highlight-title h1 {
  padding-top: 10px;
  margin: 0;
}
@keyframes bounceIn {
  from {
    opacity: 0;
    transform: translateY(-40px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  70% {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
  }
}

/* Apply the animation to each part */
.product-highlight-title,
.product-highlight-image,
.product-highlight-text,
.product-highlight-cta {
  animation: bounceIn 3s ease-in-out;
}

@media (max-width: 768px) {
    .product-highlight-content {
        flex-direction: column;
    }
    .product-highlight-text p {
        text-align: center;
    }
    .product-highlight-text {
        margin-left: 0;
        width: 100%;
    }
    .product-highlight-image {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
    }
}
