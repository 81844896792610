/* Container styling for Admin Dashboard */
.admin-dashboard-container {
    font-size: 14px; /* smaller base font */
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  
  .admin-dashboard-title {
    font-size: 16px; /* small but slightly bigger than base */
    margin-bottom: 10px;
  }
  
  .admin-dashboard-desc {
    font-size: 13px;
    color: #555;
  }
  
  /* Group Container */
  .group-container {
    border: 1px solid #ddd;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 20%
  }
  
  .group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .group-name {
    font-weight: bold;
    font-size: 13px;
  }
  
  .user-item {
    margin-bottom: 5px;
    font-size: 13px;
  }
  
  /* Buttons */
  .admin-btn-toggle {
    background-color: #f0f0f0 !important;
    color: #333 !important;
    border: 1px solid #ccc !important;
    padding: 5px 10px !important;
    font-size: 12px !important;
    border-radius: 3px !important;
    cursor: pointer !important;
  }
  .admin-btn-toggle:hover {
    background-color: #e6e6e6 !important;
  }
  
  .admin-btn-remove {
    background-color: #c00 !important;
    color: #fff !important;
    border: none !important;
    padding: 4px 10px !important;
    font-size: 12px !important;
    margin-left: 10px !important;
    border-radius: 3px !important;
    cursor: pointer !important;
  }
  .admin-btn-remove:hover {
    background-color: #900 !important;
  }
  
  .admin-btn-submit {
    background-color: #007bff !important;
    color: #fff !important;
    border: none !important;
    padding: 5px 14px !important;
    font-size: 13px !important;
    border-radius: 3px !important;
    cursor: pointer !important;
    margin-top: 15px !important;
  }
  .admin-btn-submit:hover {
    background-color: #0056b3 !important;
  }
  
  /* Horizontal line */
  .admin-hr {
    margin: 15px 0;
    border: 0;
    height: 1px;
    background-color: #ddd;
  }
  
  /* Form field styling */
  .admin-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
  }
  
  .admin-form-title {
    font-size: 14px;
    margin-bottom: 8px;
  }
  
  .form-field {
    display: flex;
    flex-direction: column;
    font-size: 13px;
  }
  
  .form-field label {
    margin-bottom: 3px;
  }
  
  .form-field input[type="text"],
  .form-field input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
    font-size: 13px;
  }
  
  /* Group assignment checkboxes */
  .group-assignment-hint {
    font-size: 12px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .group-checkbox {
    margin-left: 15px;
    margin-bottom: 3px;
    font-size: 13px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .group-list {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  /* Loader Modal Overlay */
  .loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* ensures modal is on top */
  }
  
  .loading-modal-content {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .loading-modal-content p {
    font-size: 14px;
    color: #333;
    margin: 0;
  }
  .form-field-group-assignment {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;

    max-width: 400px;
  }
  .form-field-group-assignment h4 {
    justify-self: center;
    width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }
.group-attributes-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  justify-content: space-between;
}
.group-attribute-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.user-list {
    padding: 0;
}

.user-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}