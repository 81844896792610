/* Navigation bar styles */
nav {
    display: flex;
    justify-content: center;
    background-color: transparent; /* Background color */
    backdrop-filter: blur(25px); /* Apply blur effect */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    position: fixed;
    z-index: 10;
    width: 100%;
}

.nav-wrapper {
  background-color: transparent; /* Background color */
  backdrop-filter: blur(25px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 80px;

}


.logo {
  display: flex;
  align-items: center;
  float: left;
  flex-grow: 1;
}

.logo img {
  width: 300px; /* Adjust the width as needed */
  height: auto;
  margin-right: 10px; /* Add some spacing between the logo and text */
}


/* Default styles for desktop */
.nav-links {
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
  justify-content: center; /* Center the nav links */
}
.dashboard-button-navbar {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    list-style: none;
}
.mainNavLink1,
.mainNavLink {
  position: relative; /* Make the li container relative for absolute positioning */
}
.mainNavLinkText1,
.mainNavLinkText {
  position: relative;
  color: #828282;
  text-decoration: none;
  padding-bottom: 5px; /* Add space between text and underline */
  font-size: 16px;
  font-weight: 600;
}
.mainNavLinkText1::before,
.mainNavLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #666;
  transition: width 0.5s ease-in-out;

}
.mainNavLinkText1:hover::before,
.mainNavLinkText:hover::before {
  width: 100%; /* Expand the underline to the width of the text */
}


.mainNavLinkText1:hover,
.mainNavLinkText:hover {
  color: #226389;
  transition: color .5s ease-in-out; /* Add transition for width */
}
.mainNavLinkText1 {
  color: #00b0dc; /* A fresh green color */
  font-weight: 600; /* Make it bolder */
}
/* Add these styles for the dropdown */
ul.submenu {
  list-style: none;
  display: flex;
  position: absolute;
  top: 50px; /* Adjust the top value for spacing */
  left: 50%; /* Center the dropdown under the parent li */
  transform: translateX(-50%); /* Center the dropdown horizontally */
  width: 200px; /* Adjust the width as needed */
  background-color: black; /* Background color of the dropdown */
  padding: 10px;
  border-radius: 10px; /* Add border-radius for rounded edges */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 9999;
  opacity: 0; /* Start with opacity set to 0 */
  visibility: hidden; /* Start with visibility hidden */
  transition: opacity 0.5s, visibility 0.5s; /* Add transitions for smoother appearance/disappearance */
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
}

li:hover ul.submenu {
  opacity: 1; /* Set opacity to fully visible when hovered */
  visibility: visible; /* Set visibility to visible when hovered */
}

li ul.submenu li {
  padding: 5px;
  width: 100%;
  text-align: left;
}

/* Style for the submenu links */
li ul.submenu a {
  color: #fff;
  text-decoration: none;
  display: block;
  padding: 5px; /* Add some padding for spacing */
  position: relative;
}

/* Style for the submenu link text */
li ul.submenu .subLinkText {
  position: relative;
}
.subLinkText{
    padding-bottom: 5px;
}

/* Style for the submenu link underline */
li ul.submenu .subLinkText::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #666;
  transition: width 0.5s ease-in-out;

}
/* Expand the underline's width to match the width of the submenu link text on hover */
li ul.submenu a:hover .subLinkText::before {
  width: 100%; /* Expand the width of the underline on hover */
}

li ul.submenu a:hover .subLinkText {
  color: #226389;
  transition: color .5s ease-in-out; /* Add transition for width */
}

.dashboard-button-navbar .dashboard-button {
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    will-change: box-shadow,transform;
    background: radial-gradient( 100% 100% at 100% 0%, #89E5FF 0%, #5468FF 100% );
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px rgb(58 65 111 / 50%);
    padding: 0 20px;
    border-radius: 6px;
    color: #fff;
    height: 40px;
    font-size: 18px;
    text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
    transition: box-shadow 0.15s ease,transform 0.15s ease;
}

.dashboard-button-navbar .dashboard-button:hover {
    box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #3c4fe0;
    transform: translateY(-2px);
}
.dashboard-button-navbar .dashboard-button:active{
    box-shadow: inset 0px 3px 7px #3c4fe0;
    transform: translateY(2px);
}

/* Ensure the NavLink inside the button inherits the button's text style */
.dashboard-button-text {
    color: inherit;
    text-decoration: none;
}





/* Media query for mobile screens */
@media (max-width: 768px) {
    .nav-wrapper {
        width: 100%;
        padding: 20px;
    }
    .home-text h1 {
        text-align: center;
        font-size: 40px;
    }
    .home-text p {
            text-align: center;
        }
    .home-text {
        text-align: center;
    }
  .logo {
    justify-content: center; /* Center the logo */
  }
  .logo img {
    width: 150px; /* Adjust the width as needed */
    height: auto;
  }

.nav-links {
    display: none; /* Hide the navigation links by default */
    flex-direction: column; /* Stack the links vertically */
    background-color: #f5f5f5;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 103.19px; /* Adjust the top position as needed */
    left: 0;
    transition: all 0.3s ease;
    padding: 10px 0; /* Add some padding for spacing */
    z-index: -1111; /* Ensure the dropdown is above other elements */
  }

    ul.active {
      display: block; /* Show the navigation links when active class is added */
      opacity: 1; /* Set opacity to fully visible when active */
      visibility: visible; /* Set visibility to visible when active */
      transition: opacity 0.7s, visibility 0.5s; /* Add transitions for smoother appearance/disappearance */
      padding: 0;
      padding-top: 50px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(50px); /* Apply blur effect */
      -webkit-backdrop-filter: blur(10px); /* For Safari */
    }
    ul.active::before {
        backdrop-filter: blur(50px); /* Apply blur effect */
        position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
    }
    mainNavLink1,
    .mainNavLink {
        position: relative;
        padding: 15px 0;
        margin: 0;
        text-align: center;
      }
  li {
    margin: 5px 0; /* Add spacing between each link */
  }

  .menu-icon {
    cursor: pointer;
    font-size: 1.5rem;
    color: black;
  }
  .logo {
    flex-grow: 0;
  }
  .mainNavLinkText1,
  .mainNavLinkText {
    color: #5c5c5c;
  }
  .mainNavLink:last-child {
      padding-top: 40px; /* Replace with your desired padding value */
  }
  .dashboard-button-navbar {
    justify-content: center;
  }

}
