.dashboardSBIR {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100vh;

}
.dashboard-main-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  padding-top: 0;
  align-items: center;
  overflow-y: auto !important;
  position: relative;
}
.compliance-matrix-btn-div {
    display: flex;
    align-items: center;
    width: 50%;
    justify-content: space-between;
}
.steps .step {
  margin-bottom: 20px;
}
.fade-in {
  animation: fadeInEffect 2s;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.alert {
  background-color: rgb(134, 134, 240);
  color: #080f0f;
  padding: 10px;
  margin: 10px 0; /* Add some margin for spacing */
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
  width: auto; /* Make the alert take the full width of its container */
  margin-top: 10px;
}

.alert p {
  margin: 0; /* Remove default margin */
  font-size: 15px; /* Adjust font size as needed */
  line-height: 1.5; /* Adjust line height for better readability */
  text-align: center;
}

@keyframes fadeInEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.step h3 {
  color: #007bff;
}

.step p {
  margin-top: 5px;
  line-height: 1.6;
}
.steps-btn-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0;
}
.go-back-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.go-back-div button {
    width: fit-content;
}
.AISidebar {
  /* Style your sidebar as needed */
  width: 300px; /* Example width */
  /* More styles... */
}

/* Enhancements for the dropdown container */
.dropdown-container {
    position: relative;
    width: 100%; /* Full width to match parent */
    border: 1px solid #cccccc;
    border-radius: 8px; /* Rounded corners */
    padding: 15px; /* More padding for inner spacing */
    background-color: #f9f9f9; /* Slightly off-white background for contrast */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-bottom: 20px; /* Spacing below the dropdown */
}

/* Enhancements for checkbox labels and interaction */
.dropdown-container label {
    display: flex;
    align-items: center; /* Ensure vertical alignment */
    margin-bottom: 10px; /* More space between items */
    cursor: pointer;
    transition: background-color 0.2s; /* Smooth transition for hover effect */
}

/* Hover effect for labels */
.dropdown-container label:hover {
    background-color: #e6e6e6; /* Light grey background on hover */
    border-radius: 6px; /* Rounded corners for the hover effect */
}

/* Styling for product names or group labels */
.dropdown-container strong {
    display: block;
    margin-top: 25px; /* Increase space before new product name */
    color: #000; /* Darker color for better readability */
    font-size: 16px; /* Slightly larger font size */
    padding-bottom: 5px; /* Padding at the bottom */
}

/* Adjustments for checkboxes for better alignment and interaction */
.dropdown-container input[type="checkbox"] {
    margin-right: 15px; /* Increase space between checkbox and label */
    cursor: pointer; /* Ensure cursor changes to pointer over checkboxes */
    width: 20px; /* Wider for easier clicking */
    height: 20px; /* Taller for easier clicking */
}

/* Special styling for the 'All' selector for clarity */
.dropdown-container > div:first-child label {
    font-weight: bold; /* Make 'All' stand out */
    margin-top: 0; /* Remove top margin for alignment */
}

.dropdown-check-list > label, .product-name-label, .file-label {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Space between checkboxes */
}

/* Specifically style product names differently if needed */
.product-name-label {
    font-weight: bold; /* Make product names stand out */
    /* Additional styling for product names if necessary */
}

.file-label {
    margin-left: 20px; /* Indent files under their product */
    font-weight: 400 !important;
    font-size: 12px;
}

input[type="checkbox"] {
    margin-right: 10px; /* Space between checkbox and text */
    cursor: pointer;
}

/* Style for button icon */
.icon {
  /* Add styling for your icon */
}

/* Style for button text */
.button-text {
  margin-left: 10px; /* Space between icon and text */
}



/* Adjust main content margin based on sidebar state */
.dashboard-main-content {
  margin-left: 60px; /* Same as sidebar width */
  margin-right: 60px; /* Same as sidebar width */
  margin: 0 0px;
  transition: margin 0.8s;
  /* Add other styling as needed */
}

/* Adjust margin-left for main content when sidebar is collapsed */
.dashboard-main-content.sidebar-collapsed {

}

.dashboard-main-content.AIsidebar-open {
  transition: margin 0.7s;
}
.create-reference-from-url {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid #ddd; /* Light border */
  padding: 15px; /* Add padding inside the border */
  border-radius: 8px; /* Optional: rounded corners */
  background-color: #f9f9f9; /* Optional: light background color */
  margin-top: 50px;
}

.beta-sticker {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff4757;
  color: white;
  padding: 2px 8px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  text-transform: uppercase;
  z-index: 1;
}
/* Container for the form */
.create-reference-file {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* General form styling */
.create-reference-file form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Form group styling */
.form-group {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* Label styling */
.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left;
  align-self: flex-start;
  
}
.tooltip-icon-topics {
  font-weight: 400;
  margin-left: 5px;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 50%;
  padding: 1px 8px;
  position: relative;
}
.tooltip-wrapper {
  position: relative;
  display: inline-block;
}
.tooltip-wrapper-next {
  position: relative;
  display: inline-block;
}
.tooltip-next {
  visibility: hidden;
  background-color: #061f38; /* Change to a visually appealing blue color */
  color: #fff;
  text-align: center;
  border-radius: 8px; /* More rounded corners */
  padding: 10px; /* Increased padding for more space */
  position: absolute;
  z-index: 999;
  top: 100%; /* Position the tooltip below the button */
  left: -200%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 400px; /* Adjust the width as needed */
  width: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
  font-size: 14px; /* Slightly larger text for readability */
}
.tooltip {
  visibility: hidden;
  background-color: #061f38; /* Change to a visually appealing blue color */
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 999;
  top: 110%; /* Position the tooltip below the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  min-width: 300px; /* Adjust the width as needed */
  width: auto;
}

.tooltip-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.tooltip-wrapper-next:hover .tooltip-next {
  visibility: visible;
  opacity: 1;
}



.tooltip-content-topics {
  visibility: hidden;
  background-color: #f9f9f9;
  color: #000;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 40px; /* Adjust this value to position the tooltip above the icon */
  left: 130%;
  transform: translateX(-50%);
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.tooltip-content-topics::after {
  content: '';
  position: absolute;
  top: 100%; /* Arrow at the bottom */
  left: 46%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f9f9f9 transparent transparent transparent;
}

.tooltip-icon-topics:hover .tooltip-content-topics {
  visibility: visible;
}
.error-banner-draft {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}


/* Textarea styling */
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allows vertical resizing only */
  min-height: 30px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  color: black
}

/* Button styling */
.create-reference-file button[type="submit"] {
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  width: auto;
}

/* Button hover effect */
.create-reference-file button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Optional: Styling the "Create Reference File" toggle button */
.create-reference-file > button {
  background-color: #28a745;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

/* Hover effect for the toggle button */
.create-reference-file > button:hover {
  background-color: #218838;
}
.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Adds space between the buttons */
  margin-top: 20px;
}

/* Styling for the Cancel button */
.cancel-btn-form {
  background-color: #e2505f !important;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

/* Hover effect for the Cancel button */
.cancel-btn-form:hover {
  background-color: #c82333;
}

.manageDBTab2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.manageDBTab button {
    margin-bottom: 25px;
}
.newProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    padding-bottom: 40px;
}
.newProduct ul li {
    display: flex;
    text-align: left;
    align-items: center;
}
.newProduct ul li button {

}
.upload-success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}
.generateProposalTab {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dashboard-console {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: space-around;
    padding: 20px;
    align-items: center;
    width: 75%;
    margin: 2rem auto;
    margin-top: 10px;
}
.allProducts {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    justify-content: space-around;
    padding: 20px;
    align-items: center;

}
.product-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;



}
.test2 ol ol {
  padding-left: 40px; /* Increase padding for nested lists */
  margin-top: 5px; /* Optional: add space above nested list */
  margin-bottom: 5px; /* Optional: add space below nested list */
}
.product1 {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: flex-start;
    padding: 20px;
    width: 30%;
    align-items: center;
    height: fit-content;
    margin: 10px;
    max-height: 600px;
    overflow: auto;
    position: relative;
}
/* Scrollbar styling */
.dashboard-main-content::-webkit-scrollbar,
.product1::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}
.dashboard-main-content::-webkit-scrollbar-track,
.product1::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
  border-radius: 10px;
}
.dashboard-main-content::-webkit-scrollbar-thumb,
.product1::-webkit-scrollbar-thumb {
  background-color: #afafaf; /* Thumb color */
  border-radius: 10px;
  border: 2px solid #f1f1f1; /* Optional: adds space around thumb */
}
.dashboard-main-content::-webkit-scrollbar-thumb:hover,
.product1::-webkit-scrollbar-thumb:hover {
  background-color: #969595; /* Darker thumb color on hover */
}
.product {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    justify-content: space-around;
    padding: 10px;
    width: 30%;
    align-items: center;
    margin: 10px;
}
.product1 ul,
.product ul {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 0;
}
.product1 ul li,
.product ul li {
    display: flex;
    text-align: left;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.product1 ul li p,
.product ul li p {
    font-size: auto;
    width: 85%;
}
.dashboard h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-weight: 500;
}

.dashboard input[type="file"] {
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  margin-top: 20px;
}
.inputProductName {
  padding: 10px 15px;
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
  font-size: 16px; /* Comfortable font size */
  color: #333; /* Readable text color */
  width: 100%; /* Full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  margin-bottom: 10px; /* Space between this input and the next element */
}

.inputProductName:focus {
  border-color: #007bff; /* Highlight color on focus */
  box-shadow: 0 0 0 2px rgba(0,123,255,.25); /* Subtle glow effect */
  outline: none; /* Remove default focus outline */
}

.dropzone:hover {
  border-color: #0056b3; /* Darker blue on hover */
  background-color: #e2e6ea; /* Slightly darker background on hover */
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.dashboard button {
  display: block;
  padding: 10px;
  margin: 10px;
  background-color: #00b0dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.complianceMatrixBtnDivSBIR {
  align-self: center;
  margin-top: auto;

}

.complianceMatrixBtnDivSBIR button {
  display: block;
  padding: 10px;
  margin: 10px;
  background-color: #00b0dc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: bottom;

}
.streamed-content-html {
    display: flex;
}
.file-upload-container {
  margin: 20px 0;
}

.file-upload-label {
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #007bff; /* Blue */
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 16px;
}

.file-upload-label:hover {
  background-color: #0056b3; /* Darker blue */
}

.file-info {
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.file-info p {
  margin: 0;
  font-size: 14px;
  flex-grow: 1; /* Ensure the file name takes up the space */
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  /* Add dimensions or other styles as needed */
  width: 50%; /* Example width */
  height: 200px; /* Example height */
  justify-content: center;
  color: black; /* Example text color */
  font-size: 30px;
  display: flex;
  align-items: center;
}

.remove-file-button {
  margin-left: 10px !important;
  cursor: pointer !important;
  background-color: #dc3545 !important; /* Bootstrap danger color for delete actions */
  border: none !important;
  color: white !important;
  border-radius: 50% !important; /* Makes the button circular */
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 1 !important; /* Adjust line height to vertically center the 'X' */
}

.remove-file-button:hover {
  background-color: #c82333; /* Darker shade for hover effect */
}

.streamed-content-container {
    max-height: 90%;
    overflow: auto;
}
.streamed-content-container-proposal-structure {
    max-height: 90%;
    overflow: auto;
    width: 100%;
}
.streamed-content h1,
.streamed-content {
    text-align: left;
}
.dashboard button:hover {
  background-color: #0056b3;
}

.dashboard button:disabled {
  background-color: #cccccc;
  cursor: default;
}
.proposal-process-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.dashboard a {
  display: inline-block;
  color: blue;
  text-decoration: underline;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.streamed-content-container {
  font-family: Arial, sans-serif;
  margin: 20px;
  margin-top: 10px;
}

.compliance-matrix table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.compliance-matrix th, .compliance-matrix td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.compliance-matrix th {
  background-color: #4CAF50;
  color: white;
}

.compliance-matrix tr:nth-child(even) {background-color: #f2f2f2;}

.compliance-matrix tr:hover {background-color: #ddd;}

.compliance-matrix td, .compliance-matrix th {
  padding: 12px 20px;
}

.compliance-matrix th {
  padding-top: 12px;
  padding-bottom: 12px;
}

.streamed-content-proposal-structure {
    overflow: auto;
}
.streamed-content-proposal-structure h1,
.streamed-content-proposal-structure {
    text-align: left;
}
.streamed-content-container-proposal-structure h2 {
  color: #333;
}

.streamed-content-proposal-structure {
  display: block; /* Initial display set to block, will be overridden by inline styles */
  margin-bottom: 20px;
}
.excel-export-button {
    width: fit-content;
}
.proposal-volumes-div {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 25px;
}
.proposal-volume {
  display: none; /* Enable flex layout */
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(30% - 20px); /* Subtract the gap from the total width */
  min-height: 100px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}


.proposal-volume h3 {
  color: #0275d8;
  margin-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
}

.proposal-volume ul {
  list-style: none;
  padding: 0;
  text-align: left;
  margin-bottom: 0;
  width: 100%;
}
.submitProposalName {
    align-self: center;
}
/* Styling for buttons with specific patterns in their class name */
button[class*="remove-section-btn-"],
button[class*="add-section-btn-"] {
  cursor: pointer;
  margin: 5px;
  padding: 8px 12px;
  border: none;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  font-weight: bold;
  width: 25px;
  height: 25px;

}

/* Specific styles for remove-section buttons */
button[class*="remove-section-btn-"] {
  background-color: #dc3545; /* Red for deletion actions */
  display: flex;
  align-items: center;
  justify-content: center;
}

button[class*="remove-section-btn-"]:hover {
  background-color: #c82333;
}

/* Specific styles for add-section buttons */
button[class*="add-section-btn-"] {
  background-color: #28a745; /* Green for addition actions */
  display: flex;
  align-items: center;
  justify-content: center;
}

button[class*="add-section-btn-"]:hover {
  background-color: #218838;
}
.test2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
    margin-top: 0;
}
.btn-wrapper-div-structure {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.file-name-products {
  display: inline-block; /* Allows the use of max-width */
  max-width: 90%; /* Leaves space for the download icon */
  margin-right: 10px; /* Space before the download icon */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; /* Indicates the item is clickable */
  color: #007bff; /* Bootstrap primary blue for links, adjust as needed */
  text-decoration: underline; /* Indicates it's clickable */
}

.file-name-products:hover {
  color: #0056b3; /* Darker blue on hover, adjust as needed */
  text-decoration: none; /* Optional style on hover */
}
.proposal-volume-header {
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Vertically align items */
  position: relative; /* For positioning the remove button */
  padding: 10px;
  text-align: center;
  width: 100%;
  padding-bottom: 0px;
}


.proposal-volume-header h3:focus {
  background-color: #e0f7fa; /* Highlight when focused */
  border-color: #0275d8; /* Solid border on focus */
  outline: none; /* Remove default outline */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Add a glow effect */
}
.proposal-volume-remove-btn {
  background-color: #f44336 !important; /* Red color for deletion, adjust as needed */
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  padding: 5px 10px !important;
  border-radius: 50% !important; /* Circular button */
  font-size: 16px;
  height: 30px;
  width: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.proposal-volume-remove-btn:hover {
  background-color: #d32f2f; /* Darker shade for hover effect */
}


/* General styling for all toggle editability buttons */
[class^="toggle-editability-btn-"] {
  padding: 8px 16px !important;
  color: white !important;
  border: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

[class^="toggle-editability-btn-"]:hover {
  background-color: #0056b3 !important;
}

[class^="toggle-editability-btn-"]:active {
  background-color: #004289 !important;
}

[class^="toggle-editability-btn-"]:disabled {
  background-color: #cccccc !important;
  cursor: not-allowed !important;
}

.test2 ul,
.test2 ol {
    padding: 0;
    font-size: 13px;
}
.editable-content-structure {
    width: 90%;
}
.section-and-remove-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

/* Dashboard.css */

/* Container for SAM opportunities */
.sam-opportunities {
    background-color: #f9f9f9; /* Light background */
    padding: 20px; /* Spacing around the content */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-top: 20px;
}

.sam-opportunities h2 {
    color: #333; /* Dark text color */
    margin-bottom: 20px; /* Spacing below the heading */
}

.sam-opportunities ul {
    display: flex; /* Use flexbox for horizontal layout */
    justify-content: space-between; /* Space out the items evenly */
    list-style-type: none; /* Remove default list style */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove default margin */
}

.sam-opportunities li {
    background-color: #fff; /* White background for list items */
    padding: 15px; /* Spacing inside list items */
    border: 1px solid #ddd; /* Light border */
    border-radius: 4px; /* Slightly rounded corners */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); /* Subtle shadow */
    flex: 1; /* Allow items to grow and shrink equally */
    margin: 0 10px; /* Space between items */
    display: flex; /* Flexbox for internal layout */
    flex-direction: column; /* Vertical layout */
    justify-content: space-between; /* Space out content inside the items */
    text-align: left;
}

.sam-opportunities li:first-child {
    margin-left: 0; /* Remove left margin for the first item */
}

.sam-opportunities li:last-child {
    margin-right: 0; /* Remove right margin for the last item */
}

.sam-opportunities h3 {
    margin: 0 0 10px 0; /* Space below the title */
    color: #0073e6; /* Blue color for title */
}

.sam-opportunities p {
    margin: 5px 0; /* Space between paragraphs */
    color: #555; /* Dark grey text color */
}

.sam-opportunities p span {
    display: block; /* Display contact details on new lines */
    margin-top: 5px; /* Spacing between contact details */
}

.sam-opportunities a {
    display: inline-block; /* Make the link an inline block */
    margin-top: 10px; /* Space above the link */
    padding: 10px 15px; /* Padding inside the link */
    background-color: #0073e6; /* Blue background for the link */
    color: #fff; /* White text color */
    text-decoration: none; /* Remove underline */
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s ease; /* Smooth transition */
    text-align: center;
    width: 50%;
    align-self: center;
    font-size: 12px;
}

.sam-opportunities a:hover {
    background-color: #005bb5; /* Darker blue on hover */
}
.sam-opportunities button {
    margin-top: 10px; /* Space above the button */
    padding: 10px 15px; /* Padding inside the button */
    color: #fff; /* White text color */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition */
    width: 50%;
    align-self: center;
    font-size: 12px;
}

.sam-opportunities button:hover {
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  min-width: 75%;
  width: auto;
}

.top-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-column, .right-column {
  flex: 1;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: auto;
}
.right-column {
  height: auto; /* Adjust height automatically based on content */
  flex: 2; /* Make the right column slightly larger, adjust as needed */
}

.solicitationDetails, .requirements {
  display: flex;
  flex-direction: column;
}

.topics-group h3,
.solicitationDetails h2, .requirements h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
  font-size: 24px;
  text-align: left;
  cursor: pointer;
}
.topics-group h3 {
    margin-bottom: 20px;
}

.requirements h3 {
    font-size: 24px;
    margin-bottom: 20px;
}

.submission-requirements h5 {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
}
.subRequirementUL {
    padding: 0px;
}
.submission-requirements {
    height: auto;

}
.requirement-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
}

.requirement-item.completed {
    text-decoration: line-through;
    color: gray;
}

.requirement-item input[type="checkbox"] {
    margin-right: 10px;
}

.solicitationDetails p, .requirements p, .topic p, .subtopic p {
  margin: 5px 0;
  line-height: 1.5;
  text-align: left;
}

.solicitationDetails h3, .requirements h4, .topic h3, .subtopic h4 {
  color: #0056b3;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  text-align: left;
}

.requirement-section {
  margin-top: 10px;
  padding-left: 15px;
  border-left: 3px solid #0056b3;
}

.requirement-section ul {
  padding-left: 20px;
  list-style-type: disc;
  text-align: left;
}

.requirement-section ul li {
  margin-bottom: 5px;
}

.topics-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #f9f9f9; /* Light background color */
  border: 1px solid #ddd; /* Light border around the section */
  padding: 20px; /* Padding inside the section */
  margin: 20px 0; /* Margin around the section */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.topic {
  padding: 10px;
  background-color: #f1f4f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.subtopic {
  padding: 10px;
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.topic-details,
.subtopic-details {
    width: 100%;;
}
.topic h3, .subtopic h4 {
  color: #0056b3;
  margin-top: 0;
  margin-bottom: 0px;
  text-align: left;
}

.topic p, .subtopic p {
  margin: 5px 0;
  text-align: left;
}

.complianceMatrixBtnDivSBIR {
  margin-top: 10px;
}

strong {
  color: #333;
  font-weight: bold;
}

p strong {
  color: #000;
  font-weight: bold;
}

p, ul, li {
  font-size: 14px;
  text-align: left;
}

h2, h3, h4, h5 {
  font-weight: 500;
}

.requirement-section ul {
  padding-left: 20px;
  list-style-type: disc;
  text-align: left;
}
/* Add this to your CSS file */

.collapsible {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.collapsible:hover {
  cursor: pointer;
}

.collapsible-icon {
  transition: transform 0.3s ease;
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
}

.collapsible-content.show {
  max-height: 500px; /* Adjust this as needed */
}

.rotate {
  transform: rotate(180deg);
}
.product-json {
  background-color: #f0f8ff; /* Light blue background */
  position: relative; /* Ensure the banner is positioned relative to this container */
}

.sbir-banner {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.9em;
  z-index: 10;
}
.file-remove-btn {
  background: none !important;
  border: none !important;
  color: red !important;
  cursor: pointer !important;
  font-size: 1.2em !important;
  padding: 0 !important;
  margin-left: 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.file-remove-btn i {
  pointer-events: none !important; /* Prevent click event on icon itself */
}
.file-remove-btn:hover {
  color: darkred !important; /* Darker red on hover */
}

/* For topics */
.topic-title-input {
  width: 90%;
  padding: 4px;
  margin: 8px 0;
  box-sizing: none;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  color: #0056b3;
  font-weight: 600;
  background-color: #f1f4f9;
}

.topic-description-textarea {
  width: 90%;
  padding: 0px;
  box-sizing: none;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  background-color: #f1f4f9;
}

/* For subtopics */
.subtopic-title-input {
  width: 90%;
  padding: 4px;
  margin: 8px 0;
  box-sizing: none;
  border: none;
  border-radius: 4px;
  font-size: 20px;
  color: #0056b3;
  font-weight: 600;
}

.subtopic-description-textarea {
  width: 90%;
  padding: 0px;
  box-sizing: none;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  background-color: #ffffff;
}

/* Button styling */
.topic button,
.subtopic button {
  background-color: #00b0dc;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 8px 0;
  cursor: pointer;
  border-radius: 4px;
}

.topic button:hover,
.subtopic button:hover {
  background-color: #007b9e;
}

.subtopic-volume-remove-btn,
.topic-volume-remove-btn {
        background: none !important;
        border: none !important;
        color: red !important;
        cursor: pointer !important;
        font-size: 1.2em !important;
        padding: 0 !important;
        margin-left: 10px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
.topic-volume-remove-btn {
    align-self: top;
}
.collapsible {
  cursor: pointer;
  margin: 0;
}

.collapsible-icon {
  margin-left: 10px;
}

.collapsible-content {
  display: none;
}

.collapsible-content.show {
  display: block;
  max-height: none;
}


.popup-content p {
  text-align: center;
  font-size: 15px;
}
.product1 h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}





/* Container for the entire workflow details section */
.workflow-details {
  padding: 15px;
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.4;
}

/* Deadlines and status grid */
.deadlines-grid {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Adds spacing between each item */
}

.deadline-section {
  margin-bottom: 20px; /* Spacing between the pre-app and full-app sections */
}
.deadline-header-container {
  display: flex; /* Use flexbox to arrange items in a row */
  justify-content: flex-start; /* Space between items */
  align-items: center; /* Center items vertically */
  margin-bottom: 10px; /* Add spacing below this container if needed */
  margin-top: 25px;
  cursor: pointer;
}
.deadline-header {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-align: left;
}

/* Deadline item styling */
.deadline-item {
  display: flex;
  align-items: center;
  padding-top: 5px;
  gap: 15px;
}

.deadline-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #007BFF; /* Blue for deadline icons */
}

.deadline-details {
  display: flex;
  flex-direction: row; /* Align deadline and status side by side */
  gap: 15px;
  align-items: center;
}

.deadline-sticker {
  background-color: #e0f7fa;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #007BFF;
}

/* Status tag styles */
.status-sticker {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  display: inline-block;
  text-align: center;
}

/* Status tag colors based on status */
.status-sticker.done {
  background-color: #28a745;
}

.status-sticker.not.started {
  background-color: #ff5722;
}

.status-sticker.in.progress {
  background-color: #ffc107;
  color: black;
}

/* Funding item styling */
.funding-item {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.funding-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #28a745;
}

/* Compact display for deadlines and funding */
.compact-details {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.compact-item {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 12px;
  color: #333;
  border: 1px solid #ddd;
}

/* Icons in the compact view */
.compact-item i {
  margin-right: 6px;
  font-size: 14px;
  color: #007BFF;
}

/* Hover effect for compact items */
.compact-item:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

/* Adjustments for spacing and hover effects */
.workflow-details p:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

/* Dropdown styling for file lists */
.file-dropdown-container {
  width: 100%;
}

.file-dropdown-toggle-btn {
  background-color: #f9f9f9 !important;
  color: black !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;
}

.file-dropdown-toggle-btn i {
  margin-left: 8px !important;
  font-size: 16px !important;
}

.file-list {
  list-style: none !important;
  padding: 0 !important;
  margin-top: 10px !important;
  overflow: hidden; /* Ensures that content is clipped when height is 0 */
  transition: max-height 0.3s ease-in-out; /* Smooth height transition */
  max-height: 0; /* Initially set max-height to 0 to hide it */
}

.file-list.show {
  max-height: 600px; /* Set to a value that can accommodate the content */
}


.file-name-products {
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
}

.file-name-products i {
  margin-right: 10px !important;
}

.file-remove-btn {
  margin-left: 10px !important;
  color: red !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
}
.continue-work-btn-new {
  background-color: #f9f9f9 !important; /* Bright blue background */
  color: #00b0dc !important; /* Text color */
  padding: 5px !important; /* Padding for spacing */
  border: none !important; /* No border */
  border-radius: 5px !important; /* Rounded corners */
  font-size: 10px !important; /* Font size */
  font-weight: bold !important; /* Bold text */
  cursor: pointer !important; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  margin: 0 !important;
}

/* Add hover effect */
.continue-work-btn-new:hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Optional: Add active state for when button is clicked */
.continue-work-btn-new:active {
  transform: translateY(1px); /* Slight drop effect */
}
.delete-workflow-btn {
  position: absolute !important; /* Position the button absolutely within the product1 container */
  top: 5px !important; /* Adjust top position */
  right: 5px !important; /* Adjust right position */
  background: none !important; /* No background */
  border: none !important; /* No border */
  cursor: pointer !important; /* Pointer cursor on hover */
  color: #dc3545 !important; /* Red color for the trash icon */
  font-size: 18px !important; /* Icon size */
  transition: color 0.3s !important; /* Smooth color transition */
}

.delete-workflow-btn:hover {
  color: #c82333 !important; /* Darker red on hover */
}


/* Modal Overlay */
.scanning-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.scanning-modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
  max-width: 400px;
}

/* Title Styling */
.scanning-modal-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Text Styling */
.scanning-modal-text {
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}
.scanning-modal-text-2 {
  font-size: 1em;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

/* Spinner Container */
.scanning-modal-spinner {
  display: flex;
  justify-content: center;
}

/* Spinner Styling */
.spinner {
  width: 40px;
  height: 40px;
}


.spinner-fg {
  display: none;
}

/* Spin Animation */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
/* Progress Bar Container */
.analysis-progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
  margin-top: 10px;
}

/* Progress Bar */
.analysis-progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.4s ease;
}

/* Step Counter Text */
.analysis-progress-step {
  font-size: 0.9em;
  color: #333;
  margin-top: 40px;
}



/* Modal Overlay */
.structure-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.structure-modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
  max-width: 400px;
}

/* Title Styling */
.structure-modal-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Text Styling */
.structure-modal-text {
  font-size: 1em;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

/* Progress Bar Container */
.structure-progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
  margin-top: 10px;
}

/* Progress Bar */
.structure-progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.4s ease;
}

/* Step Counter Text */
.structure-progress-step {
  font-size: 0.9em;
  color: #333;
  margin-top: 40px;
}



/* Modal Overlay */
.first-draft-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.first-draft-modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%;
  max-width: 400px;
}

/* Title Styling */
.first-draft-modal-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Text Styling */
.first-draft-modal-text {
  font-size: 1em;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
}
.first-draft-modal-text-2 {
  font-size: 1em;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

/* Spinner Container */
.first-draft-modal-spinner {
  display: flex;
  justify-content: center;
}

/* Progress Bar Container */
.first-draft-progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  height: 10px;
  margin-top: 10px;
}

/* Progress Bar */
.first-draft-progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.4s ease;
}


.dashboard-main-content.joyride-active {
  overflow: hidden; /* Lock scrolling during the tour */
}

.joyride-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  position: absolute; /* Stay within the dashboard container */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999; /* Ensure it appears above all content */
}

.joyride-tooltip {
  max-width: 300px;
  position: absolute;
  z-index: 10000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.guide-button {
  position: fixed; /* Fixed position to make it always visible */
  top: 50%; /* Center vertically on the screen */
  left: 10px; /* Distance from the left edge */
  transform: translateY(-50%); /* Adjust for proper centering */
  background-color: #007bff; /* Match your primary theme color */
  color: white; /* White text for contrast */
  border: none; /* Remove default button borders */
  border-radius: 50%; /* Make it a circular button */
  width: 50px; /* Set the size of the button */
  height: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 9999; /* Ensure it stays above all other content */
}

.guide-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.guide-button:focus {
  outline: none; /* Remove focus outline */
}
#step19 {
  visibility: visible !important;
}


/* Modal container */
.govex-confirm-remove-volume-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

/* Overlay background */
.govex-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1px); /* Apply a blur effect */
  background-color: rgba(0, 0, 0, 0.1); /* Optional dark tint */
  z-index: 1999;
}
/* Modal content box */
.govex-modal-content {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 25px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 2001;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

/* Modal title */
.govex-modal-content h3 {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
}

/* Modal text */
.govex-modal-content p {
  margin-bottom: 25px;
  font-size: 16px;
  color: #555555;
  text-align: center;
}

/* Button container */
.govex-modal-buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

/* Confirm button */
.govex-btn-confirm {
  padding: 10px 20px !important;
  background-color: #d9534f !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.govex-btn-confirm:hover {
  background-color: #b52b27 !important;
}

/* Cancel button */
.govex-btn-cancel {
  padding: 10px 20px !important;
  background-color: #6c757d !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.govex-btn-cancel:hover {
  background-color: #495057 !important;
}

.flex-container {
  display: flex;
  gap: 1rem; /* Space between components */
  align-items: flex-start; /* Align items at the top */
}

.editor-sbir {
  font-size: 0.85rem; /* Smaller font size */
  padding: 0.5rem; /* Smaller padding */
  width: 45%;
  resize: horizontal; /* Allow horizontal resizing */
  overflow: auto; /* Enable scrolling for overflow content */
}

.outline-ai-prompts {
  font-size: 1rem; /* Default font size */
  padding: 0.5rem; /* Smaller padding */
  width: 45%;
  resize: horizontal; /* Allow horizontal resizing */
  overflow: auto; /* Enable scrolling for overflow content */
  
}

.govex-draft-primary {
  background-color: #51c41b !important; /* Primary blue color */
  color: white; /* Text color */
  padding: 12px 24px !important; /* Slightly larger padding for better touch targets */
  border: none; /* No border */
  border-radius: 6px; /* Slightly more rounded corners */
  cursor: pointer; /* Pointer cursor for clickable elements */
  font-size: 16px; /* Larger, more readable text */
  font-weight: 600; /* Bold text for emphasis */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover effects */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin: 0 !important;
  height: 100%;
}

.govex-draft-primary:hover {
  background-color: #409c29 !important; /* Darker blue on hover */
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.govex-draft-primary:active {
  background-color: #004085; /* Even darker blue for active state */
  transform: scale(0.95); /* Slight press effect */
}

.govex-draft-primary:disabled {
  background-color: #b8d4f8; /* Light blue for disabled state */
  color: #ffffff; /* Keep text color consistent */
  cursor: not-allowed; /* Indicate the button is not clickable */
  opacity: 0.6; /* Reduce opacity for a disabled look */
}


.generate-first-draft {
  flex: 1; /* Smaller portion of space */
  max-width: 10%; /* Adjust width to your preference */
  font-size: 0.85rem; /* Smaller font size */
  padding: 0.5rem; /* Compact padding */
  margin: 20px auto 20px auto;
  display: flex;
  justify-content: center;
  min-height: 100px;
}


.grants-opportunities {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.grants-opportunities-header {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  color: #333;
  background: linear-gradient(90deg, #00bfae, #00796b);
  -webkit-background-clip: text;
  color: transparent;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  padding: 10px;
}

.grants-opportunities-header span {
  margin-left: 8px;
  font-size: 30px;
}


.grants-fetch-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto 20px;
}

.grants-fetch-button:hover {
  background-color: #0056b3;
}

.grants-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.grants-card {
  flex: 0 1 calc(33.333% - 15px); /* 3 columns */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grants-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.grants-card p {
  font-size: 14px;
  margin: 5px 0;
  color: #555;
}

.grants-url {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
}

.grants-url:hover {
  text-decoration: underline;
}

.grants-add-to-pipeline-button {
  padding: 8px 12px !important;
  background-color: #28a745 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  margin-top: 10px !important;
  width: auto !important;
  align-self: center !important;
}

.grants-add-to-pipeline-button:hover {
  background-color: #218838 !important;
}

.grants-no-opportunities {
  font-style: italic;
  color: #555;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .grants-card {
    flex: 0 1 calc(50% - 15px); /* 2 columns on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .grants-card {
    flex: 0 1 100%; /* 1 column on very small screens */
  }
}
.grants-attribute-header-1,
.grants-attribute-header {
  font-weight: bold;
  color: #007bff; /* Blue for the headers */
  margin-right: 5px;
  text-align: left;
}

.grants-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.grants-description {
  font-size: 14px;
  margin-top: 10px;
  color: #555;
  line-height: 1.5;
}

.grants-card p {
  font-size: 14px;
  margin: 5px 0;
  display: flex; /* Align headers and values side by side */
  overflow-wrap: anywhere;
}

.grants-card span.grants-attribute-header {
  flex-shrink: 0;
  width: 50%; /* Consistent width for headers */
}
.grants-card span.grants-attribute-header-1 {
  flex-shrink: 0;
  width: auto; /* Consistent width for headers */
}
.grants-description {
  margin-top: 0.5em;
  font-size: 14px;
  line-height: 1.5;
  transition: max-height 0.3s ease;
}

.grants-description > div {
  transition: max-height 0.3s ease;
}

.grants-description-toggle {
  cursor: pointer;
  color: #0275d8;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-top: 0.5em;
}

.grants-description-toggle:hover {
  color: #0056b3;
}

.grants-url-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.grants-url-view-opportunity-button {
  padding: 8px 12px !important;
  background-color: #007bff !important;
  color: #fff !important;
}
.grants-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; /* Space between columns */
}

.grants-column {
  flex: 1; /* Equal width for both columns */
  min-width: 200px; /* Minimum width for each column */
}

.grants-column p {
  margin: 5px 0; /* Spacing between rows in each column */
}

.grants-attribute-header {
  font-weight: bold;
  color: #007bff;
  display: inline-block;
  width: 120px; /* Align headers with consistent width */
}
.grants-url-view-opportunity-button {
  background-color: #007bff !important;
  color: white !important;
  padding: 0.5em 1em !important;
  text-decoration: none !important;
  border-radius: 5px !important;
  display: inline-block !important;
  transition: background-color 0.3s ease !important;
}

.grants-url-view-opportunity-button:hover {
  background-color: #0056b3 !important;
}
.grants-url-view-opportunity-button-disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  padding: 0.5em 1em !important;
  border-radius: 5px !important;
  border: none !important;
  cursor: not-allowed !important;
}
.grants-similarity-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.circular-progress-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.circular-progress {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #e6e6e6; /* Background circle color */
  stroke-width: 2.5;
}

.circle-progress {
  fill: none;
  stroke: #4caf50; /* Progress bar color */
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-dasharray: 0, 100; /* Initial value */
  transition: stroke-dasharray 0.3s ease; /* Smooth animation */
}

.score-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
}


.pipeline-opportunities {
  padding: 20px;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pipeline-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.pipeline-refresh-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 0 auto 20px;
}

.pipeline-refresh-button:hover {
  background-color: #0056b3;
}

.pipeline-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
}

.pipeline-card {
  flex: 0 1 calc(50% - 15px); /* 3 columns */
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pipeline-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.pipeline-card p {
  font-size: 14px;
  margin: 5px 0;
  color: #555;
}

.pipeline-attribute-header {
  font-weight: bold;
  color: #007bff;
  margin-right: 5px;
  text-align: left;
}

.pipeline-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.pipeline-description {
  font-size: 14px;
  margin-top: 10px;
  color: #555;
  line-height: 1.5;
  transition: max-height 0.3s ease;
}

.pipeline-description > div {
  transition: max-height 0.3s ease;
}

.pipeline-description-toggle {
  cursor: pointer;
  color: #0275d8;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-top: 0.5em;
}

.pipeline-description-toggle:hover {
  color: #0056b3;
}

.pipeline-url-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.pipeline-url-view-opportunity-button {
  background-color: #007bff !important;
  color: white !important;
  padding: 0.5em 1em !important;
  text-decoration: none !important;
  border-radius: 5px !important;
  display: inline-block !important;
  transition: background-color 0.3s ease !important;
}

.pipeline-url-view-opportunity-button:hover {
  background-color: #0056b3 !important;
}

.pipeline-url-view-opportunity-button-disabled {
  background-color: #cccccc !important;
  color: #666666 !important;
  padding: 0.5em 1em !important;
  border-radius: 5px !important;
  border: none !important;
  cursor: not-allowed !important;
}

.pipeline-remove-from-pipeline-button {
  padding: 8px 12px !important;
  background-color: #dc3545 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  margin-top: 10px !important;
  width: auto !important;
  align-self: center !important;
}

.pipeline-remove-from-pipeline-button:hover {
  background-color: #c82333 !important;
}

.pipeline-no-opportunities {
  font-style: italic;
  color: #555;
  text-align: center;
}

.pipeline-details-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 15px;
}

.pipeline-column {
  flex: 1;
  min-width: 200px;
}

.pipeline-column p {
  margin: 5px 0;
}

.pipeline-similarity-score {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.circular-progress-container {
  position: relative;
  width: 50px;
  height: 50px;
}

.circular-progress {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

.circle-bg {
  fill: none;
  stroke: #e6e6e6; /* Background circle color */
  stroke-width: 2.5;
}

.circle-progress {
  fill: none;
  stroke: #4caf50; /* Progress bar color */
  stroke-width: 2.5;
  stroke-linecap: round;
  stroke-dasharray: 0, 100;
  transition: stroke-dasharray 0.3s ease;
}

.score-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-weight: bold;
  color: #333;
}

@media screen and (max-width: 768px) {
  .pipeline-card {
    flex: 0 1 calc(50% - 15px); /* 2 columns on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .pipeline-card {
    flex: 0 1 100%; /* 1 column on very small screens */
  }
}



.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: Arial, sans-serif;
}

.pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3;
}

.pagination-controls button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.pagination-controls span {
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
  color: #333;
}

.pagination-controls button:focus {
  outline: none;
  box-shadow: 0 0 4px #007bff;
}
.company-profile-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: auto;
  min-width: 30%;
  margin-top: 30px;
  width: 75%;
  max-width: 75%;
}

.company-profile-header {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.profile-selection-container {
  margin-bottom: 20px;
}

.profile-label {
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
}

.profile-select-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.create-profile-button {
  background-color: #28a745 !important;
  color: white !important;
  padding: 10px 15px !important;
  border: none !important;
  border-radius: 5px !important;
  margin-top: 15px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  transition: background-color 0.3s ease !important;
}

.create-profile-button:hover {
  background-color: #218838 !important;
}

.profile-select-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-select {
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  flex: 1;
}

.delete-profile-button {
  background-color: #d9534f !important;
  color: white !important;
  padding: 10px 15px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  transition: background-color 0.3s ease !important;
}

.delete-profile-button:hover {
  background-color: #c9302c !important;
}


.company-profile-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-label {
  font-weight: bold;
  font-size: 14px;
}

.form-input,
.form-textarea {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.form-textarea {
  resize: vertical;
}

.form-input:focus,
.form-textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.form-actions {
  text-align: center;
}

.submit-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}

.btn-tour {
  background-color: #007bff !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.btn-tour:hover {
  background-color: #0056b3 !important;
}

.btn-container-start-process {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.agency-filter-container-unique {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.agency-filter-label-unique {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.agency-select-wrapper-unique {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.selected-agencies-unique {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-x: auto;
  max-height: 100%; /* Limiting height for overflow */
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  overflow-y: auto;
}

.selected-agency-unique {
  display: flex;
  align-items: center;
  background-color: #e0f7fa;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  color: #00796b;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-agency-unique .remove-agency-unique {
  margin-left: 8px;
  cursor: pointer;
  color: #d32f2f;
  font-weight: bold;
}

.selected-agency-unique .remove-agency-unique:hover {
  color: #ff5722;
}

.placeholder-text-unique {
  color: #999;
  font-style: italic;
}

.dropdown-wrapper-unique {
  width: 400px;
  text-align: left;
}

.dropdown-unique {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dropdown-unique:hover {
  background-color: #eeeeee;
}

.dropdown-label-unique {
  flex-grow: 1;
  color: #333;
}

.dropdown-icon-unique {
  color: #333;
  font-weight: 400;
  font-size: 15px;
  padding-right: 0px;
  margin-right: 0px;
  padding-left: 10px;
}

.agency-options-unique {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.agency-options-unique::-webkit-scrollbar {
  width: 8px;
}

.agency-options-unique::-webkit-scrollbar-thumb {
  background: #00796b;
  border-radius: 10px;
}

.agency-options-unique::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.agency-option-unique {
  padding: 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.agency-option-unique:hover {
  background-color: #c8e6c9;
}

.agency-option-unique.selected-unique {
  background-color: #80cbc4;
  color: white;
  font-weight: bold;
}
.profile-selector-unique {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-selector-unique label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  height: 60px;
  color: #333;
  display: flex;
  align-items: center;
}

.profile-selector-unique select {
  width: 100%;
  max-width: 300px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #fafafa;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-selector-unique select:hover {
  border-color: #00796b;
}

.profile-selector-unique select:focus {
  outline: none;
  border-color: #00796b;
  background-color: #e0f2f1;
}

.profile-selector-unique select option {
  padding: 8px 16px;
  font-size: 14px;
  color: #333;
}

.profile-selector-unique select option:hover {
  background-color: #80cbc4;
}

.profile-selector-unique select option:checked {
  background-color: #00796b;
  color: white;
}
.grants-opportunities-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f9fa; /* Light background color */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin-bottom: 20px;
  border-radius: 8px;
  height: 120px;
}

.grants-opportunities-bar .profile-selector-unique,
.grants-opportunities-bar .agency-filter-container-unique {
  margin: 0 15px;
  flex-grow: 1;
}

.grants-opportunities-bar .profile-selector-unique {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  height: 100%;
  justify-content: flex-start;
}

.grants-opportunities-bar .agency-filter-container-unique {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  box-shadow: none;
  background-color: transparent;
  height: 100%;
  justify-content: flex-start;
}

.grants-opportunities-bar select {
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
}

.grants-opportunities-bar .agency-select-wrapper-unique {
  display: inline-flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding-top: 5px;
}

.grants-opportunities-bar .agency-select-wrapper-unique .dropdown-unique {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  cursor: pointer;
  border-radius: 5px;
  background-color: white;
  color: white;
  width: 100%;
  font-size: 14px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 40px;
}

.grants-opportunities-bar .agency-select-wrapper-unique .dropdown-unique:hover {
  border: 1px solid #00796b;
}

.grants-opportunities-bar .agency-select-wrapper-unique .agency-options-unique {
  display: block;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 400px;
}

.grants-opportunities-bar .agency-option-unique {
  padding: 10px;
  cursor: pointer;
}

.grants-opportunities-bar .agency-option-unique:hover {
  background-color: #f0f0f0;
}

.grants-opportunities-bar .selected-agency-unique {
  display: inline-block;
  padding: 5px 10px;
  background-color: #00796b;
  color: white;
  border-radius: 15px;
  margin: 5px;
  font-size: 14px;
  margin-top: 0px;
}

.grants-opportunities-bar .remove-agency-unique {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.grants-fetch-button {
  padding: 10px 20px !important;
  background-color: #00796b !important;
  color: white !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  transition: background-color 0.3s !important;
}

.grants-fetch-button:hover {
  background-color: #004d40 !important;
}

.grants-fetch-button:disabled {
  background-color: #ccc !important;
  cursor: not-allowed !important;
}
.agency-filter-label-unique-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alert-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.alert-modal h3 {
  margin-bottom: 20px;
  font-size: 18px;
}

.alert-modal select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.alert-modal button {
  background-color: #00796b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.alert-modal button:hover {
  background-color: #004d40;
}

.alert-modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
/* General Modal Styling */
.alert-modal-alerts {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content-alerts {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  height: 90%;
  max-width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.your-alerts-header {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.modal-content-alerts h3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

/* Alert List */
.alert-list {
  margin-bottom: 20px;
  overflow-y: auto;
  height: 40%;
}

.alert-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.alert-item span {
  font-size: 16px;
}

.delete-alert-button-alerts {
  background-color: #d9534f !important;
  color: white !important;
  border: none !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  cursor: pointer !important;
  border-radius: 4px !important;
}

.delete-alert-button-alerts:hover {
  background-color: #c9302c !important;
}
.set-alert-button {
  background-color: #4CAF50 !important; /* Green background */
  color: white !important;
  font-size: 16px !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  display: inline-flex !important;
  align-items: center !important;
  text-align: center !important;
  justify-content: center !important;
  transition: background-color 0.3s ease !important;
  width: auto !important;
}

.set-alert-button:hover {
  background-color: #45a049 !important; /* Darker green on hover */
}

.set-alert-button:active {
  background-color: #3e8e41 !important; /* Even darker green when pressed */
}

.set-alert-button i {
  margin-right: 8px !important; /* Space between emoji and text */
}

.manage-alerts-button {
  background-color: #f39c12 !important; /* Golden color for the button */
  color: white !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  border: none !important;
  border-radius: 30px !important; /* Rounded edges for a more modern look */
  cursor: pointer !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important; /* Soft shadow for depth */
  transition: all 0.3s ease !important;
  font-weight: bold !important;
}

.manage-alerts-button:hover {
  background-color: #e67e22 !important; /* Darker golden color on hover */
  transform: translateY(-3px) !important; /* Slightly raise the button on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3) !important; /* Enhance shadow on hover */
}

.manage-alerts-button:active {
  background-color: #d35400 !important; /* Even darker color on active */
  transform: translateY(0) !important; /* Reset raise effect */
}

.manage-alerts-button i {
  margin-right: 8px !important; /* Spacing between the emoji and text */
  font-size: 20px !important; /* Make the emoji a bit larger */
}



/* Add Alert Container */
.add-alert-container-alerts {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: center;
  margin-top: 10px;
}

/* Dropdown for Selecting Agencies */
.agency-select-wrapper-unique-alerts {
  width: 50%;
  position: relative;
  margin-bottom: 10px;
}

.dropdown-wrapper-unique-alerts {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dropdown-label-unique-alerts {
  font-size: 14px;
  font-weight: 600;
}

.dropdown-icon-unique-alerts {
  font-size: 14px;
}

.agency-options-unique-alerts {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ddd;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  margin-top: 5px;
}

.agency-option-unique-alerts {
  padding: 10px;
  cursor: pointer;
}

.agency-option-unique-alerts:hover {
  background-color: #f1f1f1;
}

.selected-unique-alerts {
  background-color: #ddd;
}

/* Modal Footer */
.add-alert-button-container-alerts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  gap: 15px; /* Space between buttons */
}

/* Add Alert Button */
.add-alert-button {
  background-color: #27ae60 !important; /* Green for Add Alert */
  color: white !important;
  padding: 12px 24px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 25px !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  width: 48% !important; /* Ensures buttons are equally spaced */
}

.add-alert-button:hover {
  background-color: #2ecc71 !important;
  transform: translateY(-2px) !important;
}

/* Close Button */
.close-alert-modal {
  background-color: #e0e0e0 !important; /* Light gray for Close */
  color: #333 !important;
  padding: 12px 24px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 25px !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  width: 48% !important; /* Ensures buttons are equally spaced */
}

.close-alert-modal:hover {
  background-color: #bdbdbd !important;
  transform: translateY(-2px) !important;
}

/* Focus on accessibility */
.add-alert-button:focus,
.close-alert-modal:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(41, 128, 185, 0.5) !important;
}

/* Alert Email Notification Section */
.alert-email-notification {
  background-color: #f7f7f7;
  padding: 5px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-top: 5px;
  font-size: 16px;
  color: #333;
}

.alert-email-notification p {
  line-height: 1.6;
  margin-bottom: 12px;
}

.alert-email-notification strong {
  font-weight: bold;
}

.alert-email-notification p br {
  margin-top: 8px;
  margin-bottom: 8px;
}

.alert-email-notification p:last-child {
  margin-bottom: 0;
}

/* Highlight Important Information */
.alert-email-notification p strong {
  color: #2980b9;  /* Accent color for headings */
}

.alert-email-notification p:nth-child(2) {
  background-color: #eef2f3; /* Slight background for 'How it works' section */
  padding: 10px;
  border-radius: 6px;
}

.alert-email-notification p:nth-child(3) {
  background-color: #e9f7e5; /* Light green background for 'Why set up alerts' */
  padding: 10px;
  border-radius: 6px;
}

.dropdown-unique-alerts {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.saved-alerts-header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}
.selected-agencies-header {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.3s ease;
}

/* Modal Box */
.alert-modal {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15); /* Slight shadow for depth */
  animation: modal-entry 0.3s ease-out;
}

/* Modal Header */
.alert-modal-header-opportunities {
  font-size: 24px;
  color: #333333;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
}

/* Modal Subheader */
.alert-modal-subheader-opportunities {
  font-size: 18px;
  color: #555555;
  margin-bottom: 10px;
  font-weight: 600;
}

/* List of Agencies */
.alert-modal-list-opportunities {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
}

.alert-modal-list-item-opportunities {
  font-size: 16px;
  color: #444444;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.alert-modal-list-item-opportunities button {
  background-color: #e74c3c;
  color: white;
  padding: 6px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.alert-modal-list-item-opportunities button:hover {
  background-color: #c0392b;
}

/* Modal Footer */
.alert-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

/* Close and Save Buttons */
.modal-close-button,
.modal-save-button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modal-close-button {
  background-color: #95a5a6;
  color: white;
}

.modal-save-button {
  background-color: #3498db;
  color: white;
}

.modal-close-button:hover {
  background-color: #7f8c8d;
}

.modal-save-button:hover {
  background-color: #2980b9;
}

/* Modal Animations */
@keyframes modal-entry {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Close Button */
.alert-modal-close-button-opportunities {
  background-color: #e0e0e0 !important;
  color: #333 !important;
  padding: 12px 24px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 25px !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  width: 48% !important; /* Ensures buttons are equally spaced */
}

.alert-modal-close-button-opportunities:hover {
  background-color: #bdbdbd !important;
  transform: translateY(-2px) !important;
}

/* Save Button */
.alert-modal-save-button-opportunities {
  background-color: #27ae60 !important;
  color: white !important;
  padding: 12px 24px !important;
  font-size: 16px !important;
  font-weight: bold !important;
  border-radius: 25px !important;
  border: none !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  width: 48% !important; /* Ensures buttons are equally spaced */
}

.alert-modal-save-button-opportunities:hover {
  background-color: #2ecc71 !important;
  transform: translateY(-2px) !important;
}

/* Focus on accessibility */
.alert-modal-close-button-opportunities:focus,
.alert-modal-save-button-opportunities:focus {
  outline: none !important;
  box-shadow: 0 0 0 2px rgba(41, 128, 185, 0.5) !important;
}


.create-profile-ai-button {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%) !important; /* Gradient background */
  color: white !important;
  font-size: 16px !important;
  padding: 12px 24px !important;
  border: none !important;
  border-radius: 25px !important; /* Rounded edges with a futuristic look */
  cursor: pointer !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3) !important; /* Soft shadow for depth */
  transition: all 0.3s ease-in-out !important;
  font-weight: bold !important;
}

.create-profile-ai-button:hover {
  background: linear-gradient(135deg, #4e0d9d 0%, #1a52b7 100%) !important; /* Darker hover gradient */
  transform: scale(1.05) !important; /* Slight zoom effect on hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5) !important; /* Deeper shadow on hover */
}

.create-profile-ai-button:active {
  background: linear-gradient(135deg, #350869 0%, #123a7c 100%) !important; /* Even darker active gradient */
  transform: scale(1) !important; /* Reset zoom effect */
}

.create-profile-ai-button i {
  margin-right: 8px !important; /* Spacing between emoji and text */
  font-size: 22px !important; /* Slightly larger icon */
}






/* Modal overlay with background blur effect */
.ai-profile-modal-unique {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.6) !important; /* Semi-transparent dark overlay */
  backdrop-filter: blur(8px) !important; /* Background blur */
  z-index: 999 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* Modal content box */
.modal-content-unique-ai {
  width: 75% !important;
  background-color: #ffffff !important;
  padding: 30px !important;
  border-radius: 12px !important;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4) !important;
  animation: slideDownLarge 0.4s ease-out !important;
  height: 90% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

/* Modal header */
.modal-content-unique-ai h2 {
  font-size: 28px !important;
  font-weight: 600 !important;
  color: #333 !important;
  margin-bottom: 15px !important;
}

.ai-profile-description {
  font-size: 14px !important;
  color: #2c3e50 !important; /* Deep gray for readability */
  margin-bottom: 15px !important;
  line-height: 1.6 !important; /* Improve readability with better spacing */
  background-color: #f9f9f9 !important; /* Light background to subtly highlight the instructions */
  padding: 12px !important;
  border-radius: 5px !important;
  border-left: 4px solid #3498db !important; /* Subtle blue accent for emphasis */
  border-right: 4px solid #3498db !important; /* Subtle blue accent for emphasis */
}


/* Reference files container with scrolling */
.ai-reference-files-container-unique {
  overflow-y: auto !important;
  padding: 10px !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  background-color: #f5f5f5 !important;
}
.ai-reference-file-list-unique {
  list-style: none !important; /* Remove default bullet points */
  padding: 0 !important;
  margin: 0 !important;
}


/* Category headers inside the file list */
.ai-reference-category-unique h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #34495e !important; /* Darker shade for modern contrast */
  padding: 10px 12px !important;
  border-radius: 5px !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  margin-top: 5px !important;
}

/* Individual file item styling */
.ai-reference-file-item-unique {
  display: flex !important;
  align-items: center !important;
  padding: 10px 15px !important;
  margin-bottom: 8px !important;
  background-color: #ffffff !important; /* Clean white background */
  border: 1px solid #dcdcdc !important; /* Light border for separation */
  border-radius: 4px !important;
  transition: all 0.3s ease !important;
}
/* File item hover effect for interactivity */
.ai-reference-file-item-unique:hover {
  background-color: #f0f8ff !important; /* Light blue tint on hover */
  border-color: #2980b9 !important;
  transform: translateY(-2px) !important; /* Slight hover lift */
}
.ai-reference-file-item-unique:last-child {
  border-bottom: none !important;
}

.ai-reference-file-item-unique label {
  font-size: 12px !important;
  color: #444 !important;
  cursor: pointer !important;
}

.ai-reference-file-item-unique input[type="checkbox"] {
  margin-right: 12px !important;
  transform: scale(1.3) !important;
  accent-color: #1e90ff !important;
}

/* Hover effect for file items */
.ai-reference-file-item-unique:hover label {
  color: #1e90ff !important;
}

/* Modal actions */
.modal-actions-unique-ai {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 20px !important;
}

.cancel-button-unique-ai {
  background-color: #e74c3c !important;
  color: white !important;
  font-weight: bold !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  transition: background-color 0.3s !important;
}

.cancel-button-unique-ai:hover {
  background-color: #c0392b !important;
}

.submit-button-unique-ai {
  background-color: #27ae60 !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  transition: background-color 0.3s !important;
  font-weight: bold !important;
}

/* Smooth slide-down animation for the modal */
@keyframes slideDownLarge {
  from {
    opacity: 0 !important;
    transform: translateY(-50px) !important;
  }
  to {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }
}
.cancel-button-unique-ai:disabled,
.submit-button-unique-ai:disabled {
  background-color: #bdc3c7 !important;
  cursor: not-allowed !important;
}

.loading-indicator-ai {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 20px !important;
}

.spinner-ai {
  width: 40px !important;
  height: 40px !important;
  border: 5px solid rgba(0, 0, 0, 0.1) !important;
  border-top-color: #3498db !important;
  border-radius: 50% !important;
  animation: spin-ai 1s linear infinite !important;
}

@keyframes spin-ai {
  to {
    transform: rotate(360deg)
  }
}