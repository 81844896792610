.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    backdrop-filter: blur(5px); /* Apply blur effect */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
}


.modalContainer {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #4A90E2; /* Example: solid blue border */
}

.closeButton {
    margin-bottom: 15px;
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* You can add more styles or modify these based on your design preferences */
.emailCheckMessage {
    font-style: italic; /* Makes the text italic */
}

.spamHighlight {
    font-weight: bold; /* Makes the text bold */
}
