.AIsidebar {
  width: 350px; /* Adjust as needed */
  background-color: #eee;
  border-left: 1px solid #ddd;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  transition: all 1s ease-in-out;

}

.AIsidebar.collapsed {
  width: 50px; /* Adjusted width for collapsed state */
}

.sidebar-header.collapsed, .chat-response-textarea.collapsed, .highlighted-text-display.collapsed, .additional-notes-container.collapsed, .sidebar-footer.collapsed {
  display: none; /* Hide certain elements in collapsed state */
}

.copyButton.collapsed {
  display: none;
}


.ai-icon {
  width: 20px !important; /* Adjust width as needed */
  height: 20px !important; /* Adjust height as needed */
  transition: transform 1s ease-in-out !important;
  display: inline-block;
}
.toggle-button-ai:not(.toggle-button-ai-collapsed) .ai-icon {
    transform: rotate(-180deg) !important; /* Rotate icon when sidebar is not collapsed */
}
.toggle-button-ai {
  position: fixed; /* Fixed position to stay in place during scroll */
  top: 50%;; /* Adjust as per design */
  right: 30px; /* Adjust if sidebar is on the left side */
  z-index: 100 !important; /* Ensure it's above other elements */
  transition: right 1s ease-in-out !important;
  margin: 0 !important;
  border-radius: 25px !important;
  background-color: #00b0dc !important;
}
/* Positioning when the sidebar is collapsed */
.toggle-button-ai-collapsed {
  right: 330px; /* Or the width of the collapsed sidebar */
  transition: right 1s ease-in-out !important;
  margin: 0 !important;
  background-color: #00b0dc !important;
}




.sidebar-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.chat-history {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  border: 1px solid #eee;
  padding: 5px;
  /* Add more styling for chat messages */
}

.highlighted-text-display {
  border-top: 1px solid #141318;
  border-bottom: 1px solid #141318;
  padding: 5px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow: auto;
  min-height: 100px;
}

.highlighted-text-label {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 5px;
  color: #141318;
}

.highlighted-text-content {
  text-align: left;
  color: #141318;
}


.additional-notes-textarea {
  width: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
  min-height: 50px;
  display: flex;
  justify-content: center;
  height: fit-content;
  color: black;
  background-color: #f9f9f9;
}
.additional-notes-container {
    display: flex;
}
.sidebar-footer {
  display: flex;
  justify-content: space-between;
}

.sidebar-footer button {
  padding: 5px 10px;
  border-radius: 4px;
  border: none;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}
.chat-response-textarea {
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding: 10px;
  border-top: 1px solid #141318;
  overflow-y: auto; /* Allow scrolling for long content */
  box-sizing: border-box; /* Include padding in the element's total width and height */
  color: #141318;

}

/* Style when acting as a placeholder */
.chat-response-textarea.placeholder {
  color: #aaa; /* Placeholder text color */
}

/* Additional styles to mimic a textarea */
.chat-response-textarea:focus {
  outline: none; /* Remove outline to mimic textarea focus style */
  border-color: green; /* Example focus style */
}

.copyButton {
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 4px;
  border: none;
  background-color: transparent !important;
  color: white;
  cursor: pointer;
  background-image: url('/public/images/copy-text-svgrepo-com.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: background-image 0.3s ease;
}
.copyButton.copy-success {
  background-image: url('/public/images/icons/copy-success.svg');
  transition: background-image 0.3s ease;
}


.sidebar-footer button:last-child {
  background-color: #f44336;
}

/* Checklist Container for Reference Files */
.ref-file-checklist {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 12px;
  background-color: #fff;
  margin-bottom: 16px;
}

.ref-file-checklist h3 {
  font-size: 1.2rem;
  color: #444;
  margin: 0 0 12px;
  text-align: center;
}

/* Each Checklist Item */
.ref-file-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.ref-file-item label {
  font-size: 0.95rem;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Style for file names that are too long */
.file-name {
  display: inline-block;
  max-width: 200px;  /* Adjust the max-width as needed */
  margin-left: 8px;  /* Space between checkbox and file name */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Ensure the checkbox doesn't shrink */
.ref-file-item input[type="checkbox"] {
  margin-right: 10px;
  transform: scale(1.1);
}
.AIsidebar.collapsed .ref-file-checklist {
  display: none;
}
