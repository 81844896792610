.purchase-tokens {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.purchase-tokens:hover {
  background-color: #0056b3;
}

/* Styling for the Dropdown */
.token-select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
}

.token-select:focus {
  outline: none;
  border-color: #007bff;
}

.token-select option {
  padding: 10px;
  font-size: 16px;
}

.billed-annually {
  font-size: 12px; /* Adjust the size as needed */
  color: #666; /* Optional: Make the color slightly lighter */
}
