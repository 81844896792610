/* XtremeCloud SSO Styles */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

.outline-generator-wrapper {
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    align-items: center;
}

/* Overall Page Styles */
.outline-generator {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.generator-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 15px; /* Increased border-radius for a cooler look */
  flex-wrap: wrap;
  flex-direction: column;
  gap: 80px;
  max-width: 800px;
}

.generator-text {
  flex: 1;
  padding: 20px; /* Add spacing within the text section */
  text-align: left;
  animation-name: slideInLeft;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}
.generator-text h1 {
    font-size: 50px;
}
.generator-text p {
    font-weight: 400;
    font-size: 20px;
}
.generator-benefits h2 {
    font-size: 35px;
    font-weight: 500;
    text-align: left;
}
.generator-benefits p {
    text-align: left;
    font-size: 18px;
}
@keyframes slideInLeft {
  from {
    transform: translateX(-150%); /* Start position, off-screen to the left */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(150%); /* Start position, off-screen to the right */
  }
  to {
    transform: translateX(0); /* End position, on-screen */
  }
}
.generator-image {
  max-width: 50%;
  max-height: 50%;
  animation-name: slideInRight;
  animation-duration: 1.5s;
  animation-delay: 0.25s; /* Delay the animation */
  animation-fill-mode: both; /* Keeps the end state after animation */
}

.generator-image img {
  width: 100%;
  height: 100%;
  border-radius: 25px; /* Increased border-radius for consistency */
}


/* Header Styles */
.header {
  text-align: center;
  padding: 20px 0;
  padding-top: 30px;
}

.header h1 {
  font-size: 50px;
  color: black;
}
.header-intro{
    padding-bottom: 125px;
    animation-name: popUp;
    animation-duration: 1.5s;
    animation-delay: .25s; /* Delay the animation */
    animation-fill-mode: both; /* Keeps the end state after animation */
}
@keyframes popUp {
  from {
    opacity: 0; /* Start with 0 opacity (invisible) */
    transform: translateY(50px); /* Start position, move down 50px */
  }
  to {
    opacity: 1; /* End with full opacity (visible) */
    transform: translateY(0); /* End position, normal position */
  }
}
/* Introduction Styles */
.introduction {
  margin: 20px 0;
  padding-bottom: 25px;
}

.introduction h2 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
}

.introduction p {
  font-size: 25px;
  color: #555;
  margin-bottom: 20px;
}
.intro-image img {
    width: 50%;
    border-radius: 45px;
}
/* Key Features Styles */
.key-features {
  padding: 50px 0;
  display: flex;
  max-height: 1000px;
  max-width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
}

.key-features h2 {
  font-size: 1.5rem;
  color: #007bff;
  margin-bottom: 10px;
  width: 100%;
}

/* Feature Card Styles */
.feature-card {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
  height: 100px;
  width: 50%;
  overflow: hidden;
}

.feature-icon {
  /* You can add an icon here using background-image or font icons */
  width: 60px; /* Adjust the size as needed */
  height: 60px;
  border-radius: 50%;
  background-color: #226389; /* You can customize the color */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-left: 20px;
  color: white;
  font-size: 1.5rem;
}

.feature-details {
  flex: 1;
}

.feature-details h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

.feature-details p {
  font-size: 1rem;
  color: #555;
}


/* Buttons Styles */
.buttons {
  text-align: center;
  margin-top: 30px;
}

.learn-more-button-Generator,
.contact-button-generator {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.2rem;
  text-decoration: none;
  color: #fff;
  background-color: #00b0dc;
  border: none;
  border-radius: 5px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more-button-Generator:hover,
.contact-button-generator:hover {
  background-color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .outline-generator {
    padding: 0;
  }
  .generator-text h1 {
    font-size: 30px;
  }
  .generator-text p {
      font-size: 18px;
  }

  .header h1 {
    font-size: 2rem;
  }

  .introduction h2 {
    font-size: 1.3rem;
  }

  .introduction p {
    font-size: 1rem;
  }

  .key-features h2 {
    font-size: 1.3rem;
  }

  .feature-list {
    font-size: 1rem;
  }

  .learn-more-button,
  .contact-button {
    font-size: 1rem;
  }
}

.buttons{
    display: flex;
    justify-content: space-evenly;
}
/* Multi-Cloud vs. DR/COOP Section Styles */
.multi-cloud-vs-drcoop {
  padding: 20px;
  background-color: #f9f9f9;
}

.multi-cloud-vs-drcoop h2 {
  font-size: 40px;
  color: #226389;
  margin-bottom: 10px;
}

.multi-cloud-vs-drcoop p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Side-by-Side Comparison Styles */
.comparison {
  display: flex;
  justify-content: space-around;
  background-color: #f9f9f9;
  margin-bottom: 75px;
 padding-bottom: 75px;
}

.comparison-item {
  width: 45%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.comparison-item h3 {
  font-size: 25px;;
  color: #226389;
  margin-bottom: 25px;
}

.comparison-item ul {
  list-style-type: disc;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comparison-item ul li {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}


.cloud-service-providers {
  padding: 40px 0;
}

.cloud-service-providers-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 0 20px;
}

.cloud-service-providers-text {
  flex: 1;
  margin-right: 20px;
  max-width: 40%;
}

.cloud-service-providers h2 {
  font-size: 40px;
  text-align: left;
  color: #226389;
}

.cloud-service-providers p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
}

.cloud-service-providers-list {
  flex: 1;
  max-width: 50%;
}

.cloud-service-providers ul {
  list-style: none;
  padding: 0;
  flex-direction: column;
}

.cloud-service-providers ul li {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: left;
}
@media (max-width: 768px) {
    .comparison-item ul {
        padding: 0;
    }
    .comparison-item {
        padding: 0;
    }
    .comparison-item h3 {
        font-size: 20px;
    }
    .comparison-item ul li {
        font-size: 15px;
    }
    .cloud-service-providers-content {
        padding: 0;
        flex-direction: column;
    }
    .cloud-service-providers-text {
        max-width: 100%;
    }
    .cloud-service-providers-list {
        max-width: 100%;
    }
    .cloud-service-providers h2 {
        margin-top: 0;
    }
    .feature-details h3 {
        font-size: 11px;
        margin: 0;
    }
    .feature-details p {
        font-size: 10px;
        margin: 0;
    }
    .feature-icon {
        width: 30px;
        height: 30px;
    }
}