.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #f11946;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
