/* Main container styles */
.policy-container {
    width: 90%;
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    padding-top: 160px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Section styles */
.policy-section {
    margin-bottom: 20px;
}

/* Title styles */
.policy-title {
    margin-bottom: 10px;
    color: #333;
    font-size: 24px;
}

/* Text styles */
.policy-text {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 10px; /* Adjust space between paragraphs */
}

/* Introduction section additional styles */
.introduction-section {
    background-color: #e8e8e8;
    padding: 15px;
    border-radius: 8px;
}

/* Terms section additional styles */
.terms-section {
    background-color: #e2f4f7;
    padding: 15px;
    border-radius: 8px;
}

/* Privacy section additional styles */
.privacy-section {
    background-color: #fff8e1;
    padding: 15px;
    border-radius: 8px;
}
