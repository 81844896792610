/* Contact Form Styles */

.contact-section {
  background-color: #f5f5f5;
  padding: 20px;
  padding-top: 160px;
  text-align: center;
  font-family: 'IBM Plex Sans', sans-serif;
  height: 100%;
}

.contact-heading {
  font-size: 50px;
  color: #333;
  margin-bottom: 20px;
}

.contact-text {
  font-size: 30px;
  color: #666;
  margin-bottom: 60px;
}

.contact-label {
  display: block;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  width: 50%;
}
.contact-label p {
  margin: 0;
  font-size: 30px;
  text-align: left;
}

.contact-input,
.contact-textarea {
  width: 100%;
  padding: 15px 0;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 20px;
}
.contact-input{
    padding: 15px 5px;
}
.contact-textarea {
    padding: 5px;
}

.contact-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #0056b3;
}
.contact-form{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 60px;
}

@media (max-width: 768px) {
    .contact-section {
        height: 100%;
    }
    .contact-form {
        padding: 0
    }
}